import React from 'react';

const Sample = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex">
      <aside className="w-1/4 bg-blue-900 text-white p-6">
        <h2 className="text-2xl font-bold mb-6">Dashboard</h2>
        <nav>
          <ul>
            <li className="mb-4"><a href="#overview">Overview</a></li>
            <li className="mb-4"><a href="#messages">Messages</a></li>
            <li className="mb-4"><a href="#contacts">Contacts</a></li>
            <li className="mb-4"><a href="#settings">Settings</a></li>
          </ul>
        </nav>
      </aside>

      <main className="w-3/4 p-6">
        <header className="mb-6">
          <h1 className="text-3xl font-bold">Welcome Back!</h1>
          <p className="text-gray-700">Here's what's happening with your account today.</p>
        </header>

        <section className="grid grid-cols-3 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">Total Messages</h3>
            <p className="text-2xl">1,234</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">New Contacts</h3>
            <p className="text-2xl">567</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">Total Responses</h3>
            <p className="text-2xl">890</p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Sample;
