import React, { useState, useEffect } from 'react';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [filter, setFilter] = useState('all');  // 'all', 'unread', 'read'
  const [sort, setSort] = useState('date_desc'); // Sorting: newest, oldest, status
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);

  useEffect(() => {
    // Fetch notifications from the backend API
    fetchNotifications();
  }, [currentPage, filter, sort, searchQuery]);

  const fetchNotifications = () => {
    const queryParams = new URLSearchParams({
      page: currentPage,
      filter: filter,
      sort: sort,
      search: searchQuery,
    });

    fetch(`${API_BASE_URL}/api/notifications?${queryParams.toString()}`)
      .then((response) => response.json())
      .then((data) => {
        setNotifications(data.notifications);
        setTotalPages(data.total_pages);
        // Optionally, you can count unread notifications here
        const unreadCount = data.notifications.filter(notification => notification.status === 'unread').length;
        setUnreadCount(unreadCount);
      })
      .catch((error) => {
        console.error('Error fetching notifications:', error);
      });
  };

  // Handle search form submission
  const handleSearch = (event) => {
    event.preventDefault();
    fetchNotifications();
  };

  // Mark a notification as read
  const markAsRead = (id) => {
    fetch(`/notifications/mark_as_read/${id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }).then(() => {
      // Update notification state after marking it as read
      setNotifications((prev) =>
        prev.map((notification) =>
          notification.id === id
            ? { ...notification, status: 'read' }
            : notification
        )
      );
    });
  };

  const markAllAsRead = () => {
    fetch(`/notifications/mark_all_as_read`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }).then(() => {
      setNotifications([]);
    });
  };

  // Clear all notifications
  const clearAllNotifications = () => {
    fetch(`/notifications/clear_all`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }).then(() => {
      setNotifications([]);
    });
  };

  // Toggle the dropdown state
  const toggleSortDropdown = (event) => {
    event.stopPropagation();  // Prevent this click from propagating
    setSortDropdownOpen(!isSortDropdownOpen);
  };

  // Close the dropdown if clicking outside (Optional)
  useEffect(() => {
    const closeDropdownOnClickOutside = (event) => {
      if (!event.target.closest('.sort-dropdown') && !event.target.closest('.toggle-button')) {
        setSortDropdownOpen(false);
      }
    };
    document.addEventListener('click', closeDropdownOnClickOutside);

    return () => {
      document.removeEventListener('click', closeDropdownOnClickOutside);
    };
  }, []);

  return (
    <div className="page-content px-6 py-4 font-rubik">
      {/* <h4 className="text-xl font-bold mb-6">Notifications</h4> */}
      <h3 className="text-2xl font-bold mb-2">Notifications</h3>

      {/* Filter, Search, and Sort Options */}
      <div className="flex flex-wrap justify-end items-center mb-6 space-x-4">
        <button className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded shadow" onClick={clearAllNotifications}>
          Clear All Notifications
        </button>

        <button
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded shadow"
          onClick={() => {/* Add logic to mark all as read */}}
          disabled={!unreadCount}
        >
          Mark All as Read
        </button>

        {/* Filters */}
        <div className="flex space-x-2">
          <button
            className={`px-4 py-2 rounded shadow ${
              filter === 'all' ? 'bg-gray-500 text-white' : 'bg-gray-200 hover:bg-gray-300'
            }`}
            onClick={() => setFilter('all')}
          >
            All
          </button>
          <button
            className={`px-4 py-2 rounded shadow ${
              filter === 'unread' ? 'bg-gray-500 text-white' : 'bg-gray-200 hover:bg-gray-300'
            }`}
            onClick={() => setFilter('unread')}
          >
            Unread
          </button>
          <button
            className={`px-4 py-2 rounded shadow ${
              filter === 'read' ? 'bg-gray-500 text-white' : 'bg-gray-200 hover:bg-gray-300'
            }`}
            onClick={() => setFilter('read')}
          >
            Read
          </button>
        </div>

        {/* Search Form */}
        <form className="flex items-center space-x-2" onSubmit={handleSearch}>
          <input
            type="text"
            className="form-control px-3 py-2 border rounded shadow"
            placeholder="Search notifications"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded shadow">
            Search
          </button>
        </form>

        {/* Sort Dropdown */}
        <div className="relative">
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 px-4 py-2 rounded shadow focus:outline-none toggle-button"
            onClick={toggleSortDropdown}  // Toggle on button click
          >
            Sort By: {sort === 'date_asc' ? 'Oldest First' : sort === 'status' ? 'Status' : 'Newest First'}
          </button>

          {isSortDropdownOpen && ( // Conditionally render the dropdown menu
            <div className="absolute right-0 mt-2 w-48 bg-white rounded shadow-md z-10 sort-dropdown">
              <button
                onClick={() => { setSort('date_desc'); setSortDropdownOpen(false); }}
                className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
              >
                Newest First
              </button>
              <button
                onClick={() => { setSort('date_asc'); setSortDropdownOpen(false); }}
                className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
              >
                Oldest First
              </button>
              <button
                onClick={() => { setSort('status'); setSortDropdownOpen(false); }}
                className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
              >
                Status
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Notifications List */}
      <ul className="space-y-4">
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <li
              key={notification.id}
              className={`p-4 border rounded-lg transition-all ${
                notification.status === 'unread' ? 'bg-blue-50 border-blue-400' : ''
              }`}
            >
              <div className="flex justify-between items-center">
                <a href={notification.link} className="font-normal text-blue-600 hover:underline">
                  {notification.message}
                </a>
                <small className="text-gray-500">{notification.created_at}</small>
                {notification.status === 'unread' && (
                  <button
                    className="ml-4 px-3 py-1 text-sm border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
                    onClick={() => markAsRead(notification.id)}
                  >
                    Mark as Read
                  </button>
                )}
              </div>
            </li>
          ))
        ) : (
          <li className="p-4 text-gray-500 border rounded-lg">No notifications found.</li>
        )}
      </ul>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-8 space-x-4">
        <button
          className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded shadow"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(1)}
        >
          First
        </button>
        <button
          className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded shadow"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </button>
        <span className="px-4 py-2 bg-white rounded shadow">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded shadow"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button>
        <button
          className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded shadow"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(totalPages)}
        >
          Last
        </button>
      </div>
    </div>
  );
};

export default Notifications;
