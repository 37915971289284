import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';

const UserProfile = ({ userDetails }) => {
  const [editMode, setEditMode] = useState(false);
  const [userDetailsState, setUserDetailsState] = useState(userDetails);
  const [editedDetails, setEditedDetails] = useState({ ...userDetailsState });

  const toggleEditMode = () => {
    if (editMode) {
      setUserDetailsState(editedDetails);  // Save changes
    }
    setEditMode(!editMode);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  return (
    <div className="container mx-auto px-4 py-8 font-rubik">
      {/* Profile Card */}
      <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6 bg-blue-500 text-white text-center">
          {/* <img
            className="w-32 h-32 rounded-full mx-auto mb-4"
            src="https://via.placeholder.com/150"
            alt="Profile Avatar"
          /> */}
          <h2 className="text-2xl font-semibold">{userDetailsState.user_name}</h2>
          <p>{userDetailsState.user_email}</p>
          <p className="mt-2">{userDetailsState.bio}</p>

          {/* Edit Button */}
          <button
            onClick={toggleEditMode}
            className="mt-4 bg-white text-blue-500 px-4 py-2 rounded-lg shadow-md hover:bg-blue-100 transition"
          >
            <FontAwesomeIcon icon={editMode ? faSave : faEdit} className="mr-2" />
            {editMode ? 'Save Profile' : 'Edit Profile'}
          </button>
        </div>

        {/* Tabbed Profile Navigation */}
        <TabGroup>
          <TabList className="flex justify-center space-x-4 mt-6">
            <Tab as="button" className="px-4 py-2 rounded-t-lg bg-gray-200 text-blue-600 focus:outline-none">
              Profile Info
            </Tab>
            <Tab as="button" className="px-4 py-2 rounded-t-lg bg-gray-200 text-blue-600 focus:outline-none">
              Settings
            </Tab>
            <Tab as="button" className="px-4 py-2 rounded-t-lg bg-gray-200 text-blue-600 focus:outline-none">
              Security
            </Tab>
          </TabList>

          <TabPanels className="p-6">
            {/* Profile Info Tab */}
            <TabPanel>
              <h3 className="text-xl font-semibold mb-4">Profile Information</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-gray-600">Full Name</label>
                  <input
                    type="text"
                    name="user_name"
                    value={editedDetails.user_name}
                    onChange={handleInputChange}
                    disabled={!editMode}
                    className={`w-full p-2 border rounded-lg ${
                      editMode ? 'bg-white' : 'bg-gray-100'
                    }`}
                  />
                </div>
                <div>
                  <label className="block text-gray-600">Email</label>
                  <input
                    type="email"
                    name="user_email"
                    value={editedDetails.user_email}
                    onChange={handleInputChange}
                    disabled={!editMode}
                    className={`w-full p-2 border rounded-lg ${
                      editMode ? 'bg-white' : 'bg-gray-100'
                    }`}
                  />
                </div>
                <div>
                  <label className="block text-gray-600">Bio</label>
                  <textarea
                    name="bio"
                    value={editedDetails.bio}
                    onChange={handleInputChange}
                    disabled={!editMode}
                    className={`w-full p-2 border rounded-lg ${
                      editMode ? 'bg-white' : 'bg-gray-100'
                    }`}
                  />
                </div>
              </div>
            </TabPanel>

            {/* Settings Tab */}
            <TabPanel>
              <h3 className="text-xl font-semibold mb-4">Settings</h3>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span>Receive Email Notifications</span>
                  <input type="checkbox" className="toggle-checkbox" />
                </div>
                <div className="flex justify-between items-center">
                  <span>Enable Dark Mode</span>
                  <input type="checkbox" className="toggle-checkbox" />
                </div>
              </div>
            </TabPanel>

            {/* Security Tab */}
            <TabPanel>
              <h3 className="text-xl font-semibold mb-4">Security Settings</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-gray-600">Change Password</label>
                  <input
                    type="password"
                    className="w-full p-2 border rounded-lg"
                    placeholder="Enter new password"
                  />
                </div>
                <div className="flex justify-between items-center">
                  <span>Two-Factor Authentication</span>
                  <input type="checkbox" className="toggle-checkbox" />
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  );
};

export default UserProfile;
