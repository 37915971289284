import React from 'react';
import OverviewCard from './OverviewCard';
import AnalyticsChart from './AnalyticsChart';
import NotificationFeed from './NotificationFeed';
import QuickActions from './QuickActions';

const DashboardV1 = () => {
    const chartData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        datasets: [
          {
            label: "Sent Messages",
            data: [150, 200, 250, 400, 300, 450],
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
          },
          {
            label: "Failed Messages",
            data: [20, 30, 10, 40, 50, 20],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
          }
        ]
    };
    return (
    <div className="min-h-screen bg-gray-100 flex flex-col font-rubik">
        {/* Top Navbar */}
        <header className="bg-white shadow-md w-full fixed top-0 z-10">
            <div className="container mx-auto px-4 py-3 flex justify-between items-center">
            <h1 className="text-xl font-bold text-blue-600">YourApp</h1>
            <nav className="space-x-6">
                {/* Add navigation links here */}
            </nav>
            </div>
        </header>

        {/* Sidebar */}
        <aside className="fixed left-0 top-14 w-64 h-full bg-white shadow-md p-4">
            <ul>
            {/* Add Sidebar Links */}
            </ul>
        </aside>

        {/* Main Dashboard Area */}
        <main className="ml-64 pt-20 p-8 flex-1 space-y-6">
            {/* Overview Cards */}
            <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <OverviewCard title="Sent Messages" value="1,500" icon={<i className="fas fa-envelope" />} />
            <OverviewCard title="Failed Messages" value="30" />
            <OverviewCard title="Success Rate" value="98%" />
            <OverviewCard title="Recent Activity" value="Updated just now" />
            </section>

            {/* Charts & Analytics */}
            <section className="bg-white shadow rounded-lg p-6">
            <AnalyticsChart data={chartData} title="Monthly Message Analytics" />
            </section>

            {/* Notifications and Quick Actions */}
            <section className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <NotificationFeed />
            <QuickActions />
            </section>
        </main>
    </div>
  );
};

export default DashboardV1;
