import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const FlowList = ({ flows }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.closest('a')) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  return (
    <div>
      <ul className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 w-full">
        {flows.map((flow) => (
          <li
            key={flow.flow_id}
            className="p-4 bg-white rounded-lg shadow-md"
          >
            <div className="flex justify-between items-center mb-3">
              <div className="font-medium">{flow.flow_name}</div>
              <div
                className={`px-2 py-1 rounded text-sm ${
                  flow.status === 'PUBLISHED' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'
                }`}
              >
                {flow.status}
              </div>
            </div>

            {/* Actions Dropdown */}
            <div className="flex justify-end mt-4">
              <div className="relative inline-block text-left" ref={dropdownRef}>
                <button onClick={() => toggleDropdown(flow.flow_id)} className="flex items-center justify-center w-full px-2 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
                  Actions
                  <svg
                    className="w-5 h-5 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14M12 5l7 7-7 7" />
                  </svg>
                </button>

                {openDropdown === flow.flow_id && (
                  <div className="absolute right-0 mt-2 w-48 origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1">
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Edit
                      </a>
                      <Link to="/flows/send-a-flow" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Send to a Customer
                      </Link>
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Refresh Status
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </li>
        ))}
        </div>
      </ul>
      {/* Pagination */}
      <div className="flex justify-center mt-6">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className="bg-gray-300 px-4 py-2 rounded-md mr-2"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className="bg-gray-300 px-4 py-2 rounded-md"
          disabled
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default FlowList;
