import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faBullhorn, faEllipsisH, faTimes, faRandom } from '@fortawesome/free-solid-svg-icons';
import { faFileLines, faPaperPlane, faCalendarPlus } from '@fortawesome/free-regular-svg-icons';

const LandingV1 = ({ userName }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 px-8 py-8 font-rubik">
      {/* Welcome Section */}
      <div className="text-center mb-12">
        <h3 className="text-3xl font-bold text-gray-800 mb-4">Welcome, {userName}!</h3>
        <p className="text-gray-600 text-lg">Quick access to your essential features</p>
      </div>

      {/* Key Actions Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-3xl mx-auto">
        {/* Add Message Template */}
        <div className="bg-white shadow-md rounded-lg p-4 text-center hover:shadow-lg transition-shadow duration-300">
          <FontAwesomeIcon icon={faFileLines} className="text-3xl text-blue-600 mb-3" />
          <h5 className="text-lg font-semibold text-gray-800">Add Message Template</h5>
          <p className="text-gray-500 text-sm mt-1 mb-3">Create new templates for your campaigns.</p>
          <Link to="/message-templates/add-template" className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm hover:bg-blue-700 transition">
            Create Template
          </Link>
        </div>

        {/* Send a Message */}
        <div className="bg-white shadow-md rounded-lg p-4 text-center hover:shadow-lg transition-shadow duration-300">
          <FontAwesomeIcon icon={faPaperPlane} className="text-3xl text-blue-600 mb-3" />
          <h5 className="text-lg font-semibold text-gray-800">Send a Message</h5>
          <p className="text-gray-500 text-sm mt-1 mb-3">Send a personalized message to a customer.</p>
          <Link to="/message-templates/send-a-message" className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm hover:bg-blue-700 transition">
            Send Message
          </Link>
        </div>

        {/* Add Customer Segment */}
        <div className="bg-white shadow-md rounded-lg p-4 text-center hover:shadow-lg transition-shadow duration-300">
          <FontAwesomeIcon icon={faUsers} className="text-3xl text-blue-600 mb-3" />
          <h5 className="text-lg font-semibold text-gray-800">Add Customer Segment</h5>
          <p className="text-gray-500 text-sm mt-1 mb-3">Organize customers for targeted messaging.</p>
          <Link to="/customer-segments-v7/add-segment" className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm hover:bg-blue-700 transition">
            Add Segment
          </Link>
        </div>

        {/* Send Message to Segment */}
        <div className="bg-white shadow-md rounded-lg p-4 text-center hover:shadow-lg transition-shadow duration-300">
          <FontAwesomeIcon icon={faBullhorn} className="text-3xl text-blue-600 mb-3" />
          <h5 className="text-lg font-semibold text-gray-800">Send Message to Segment</h5>
          <p className="text-gray-500 text-sm mt-1 mb-3">Send a message to a specific segment.</p>
          <Link to="/send-to-segment" className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm hover:bg-blue-700 transition">
            Send to Segment
          </Link>
        </div>

        {/* Send Flow */}
        <div className="bg-white shadow-md rounded-lg p-4 text-center hover:shadow-lg transition-shadow duration-300">
          <FontAwesomeIcon icon={faRandom} className="text-3xl text-blue-600 mb-3" />
          <h5 className="text-lg font-semibold text-gray-800">Send a Flow</h5>
          <p className="text-gray-500 text-sm mt-1 mb-3">Send a personalized flow to a customer.</p>
          <Link to="/flows/send-a-flow" className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm hover:bg-blue-700 transition">
            Send a Flow
          </Link>
        </div>

        {/* Schedule a Message */}
        <div className="bg-white shadow-md rounded-lg p-4 text-center hover:shadow-lg transition-shadow duration-300">
          <FontAwesomeIcon icon={faCalendarPlus} className="text-3xl text-blue-600 mb-3" />
          <h5 className="text-lg font-semibold text-gray-800">Schedule a Message</h5>
          <p className="text-gray-500 text-sm mt-1 mb-3">Schedule a message to a specific segment.</p>
          <Link to="/scheduled-messages-cards/schedule-message" className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm hover:bg-blue-700 transition">
            Schedule a Message
          </Link>
        </div>
      </div>

      {/* More Actions Button */}
      {/* <div className="text-center mt-10">
        <button
          className="text-blue-600 text-md font-semibold flex items-center justify-center space-x-2"
          onClick={() => setDrawerOpen(true)}
        >
          <span>More Actions</span>
          <FontAwesomeIcon icon={faEllipsisH} className="text-xl" />
        </button>
      </div> */}

      {/* Drawer */}
      {isDrawerOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end z-50">
          <div className="bg-white w-64 p-6 shadow-lg">
            <button className="text-gray-400 hover:text-gray-700 text-xl mb-4" onClick={() => setDrawerOpen(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="space-y-6">
              {/* Additional options */}
              <Link to="/additional-action-1" className="block text-blue-600 hover:underline">
                Additional Action 1
              </Link>
              <Link to="/additional-action-2" className="block text-blue-600 hover:underline">
                Additional Action 2
              </Link>
              {/* Repeat for other actions */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingV1;
