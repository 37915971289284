import React from 'react';

const HomePage = () => {
    return (
    <div className="bg-gray-100 border-b border-gray-300 py-4">
        <div className="flex justify-around max-w-4xl mx-auto">
            <a href="/dashboard" className="text-gray-700 font-medium">Dashboard</a>
            <a href="/settings" className="text-blue-600 font-medium">Settings</a>
            <a href="/messages" className="text-gray-700 font-medium">Messages</a>
            {/* Additional tabs as needed */}
        </div>
    </div>
    )
}

export default HomePage;
