import React from 'react';

const MessageDetailsModal = ({ isOpen, onClose, messageDetails }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg w-full max-w-2xl">
        <h2 className="text-xl font-semibold mb-4">{messageDetails.template_id} Details</h2>

        <div>
          <h3 className="text-lg font-semibold">Segment: {messageDetails.segment_id}</h3>
          <p>Scheduled Time: {new Date(messageDetails.send_time).toLocaleString()}</p>
          <p>Created At: {new Date(messageDetails.created_at).toLocaleString()}</p>
        </div>

        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Customer Delivery Status</h3>
          <div className="overflow-y-auto max-h-60 border rounded-md p-3">
            <ul>
                {messageDetails.customers.map((customer) => (
                <li key={customer.id} className="flex justify-between border-b py-2">
                    {/* <span>{customer.name}</span> */}
                    <p className="text-xs font-light text-gray-600">
                      <span className="text-sm font-medium text-gray-700">{customer.name}</span> {customer.phone_number}
                    </p>
                    <span>{customer.delivery_status}</span>
                </li>
                ))}
            </ul>
          </div>
        </div>

        <button
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default MessageDetailsModal;
