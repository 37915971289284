import React, { useState, useEffect } from 'react';
import CustomBreadcrumbs from './CustomBreadcrumbs';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import Spinner from './Spinner';

const MessageTemplateLevelReportCharts = () => {
  const [messageStats, setMessageStats] = useState([]);
  const [dateRange, setDateRange] = useState('this_month');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templates, setTemplates] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateError, setDateError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasQueried, setHasQueried] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend
  );

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/wa_templates?status=APPROVED`);
        if (!response.ok) throw new Error('Failed to fetch templates');
        const data = await response.json();
        setTemplates(data.templates);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };

    fetchTemplates();
  }, []);

  useEffect(() => {
    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      setDateError('Start date cannot be after the end date.');
    } else {
      setDateError('');
    }
  }, [startDate, endDate]);

  const fetchReportData = async () => {
    if (dateError) return;
    setIsLoading(true);
    setHasQueried(true);
    try {
      let url = `${API_BASE_URL}/api/message_reports?template=${selectedTemplate}`;
      if (dateRange === 'custom_range' && startDate && endDate) {
        url += `&dateRange=custom_range&startDate=${startDate}&endDate=${endDate}`;
      } else {
        url += `&dateRange=${dateRange}`;
      }
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch report data');
      const data = await response.json();
      setMessageStats(data.message_stats);
    } catch (error) {
      console.error('Error fetching report data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isRunQueryDisabled = dateRange === 'custom_range' && (!startDate || !endDate || dateError);

  const chartHeight = messageStats.length > 3 ? 450 : 300;

  return (
    <div className="min-h-screen bg-gray-50 font-rubik flex">
      {/* Side Panel */}
      <div className="w-1/5 p-4 bg-white rounded-lg shadow-md mr-6">
        <h3 className="text-xl font-semibold mb-4">Filters</h3>
        <label className="block mb-2 font-medium">Date Range</label>
        <select
          className="p-2 border rounded-md w-full mb-4"
          value={dateRange}
          onChange={(e) => setDateRange(e.target.value)}
        >
          <option value="this_month">This Month</option>
          <option value="last_month">Last Month</option>
          <option value="custom_range">Custom Range</option>
        </select>

        {dateRange === "custom_range" && (
          <div>
            <label className="block mb-2 font-medium">Start Date:</label>
            <input className="p-2 border rounded-lg focus:outline-none w-full mb-4" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            {dateError && (
              <p className="text-red-600 mb-2">{dateError}</p>
            )}
            <label className="block mb-2 font-medium">End Date:</label>
            <input className="p-2 border rounded-lg focus:outline-none w-full mb-4" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
          </div>
        )}

        <label className="block mb-2 font-medium">Template</label>
        <select
          className="p-2 border rounded-md w-full"
          value={selectedTemplate}
          onChange={(e) => setSelectedTemplate(e.target.value)}
        >
          <option value="">All Templates</option>
          {templates.map((template) => (
            <option key={template.id} value={template.name}>
              {template.name}
            </option>
          ))}
        </select>

        <button
          className={`mt-4 w-full px-4 py-2 font-semibold rounded-md shadow-sm ${
            isRunQueryDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-700'
          }`}
          onClick={fetchReportData}
          disabled={isRunQueryDisabled}
        >
          Run Query
        </button>
      </div>

      <div className="flex-1">
        <CustomBreadcrumbs />
        <h2 className="text-3xl font-semibold mb-4">Message Template Level Report</h2>
        <p className="text-gray-600 mb-6">Analyze message activity based on selected filters.</p>

        {isLoading ? (
          <Spinner text="Fetching the report..." />
        ) : (
          <div>
            {hasQueried ? (
              messageStats.length > 0 ? (
                <div className="bg-white shadow-md rounded-lg p-6 mb-8 slide-up" style={{ height: `${chartHeight}px` }}>
                  <Bar
                    data={{
                      labels: messageStats.map((stat) => stat.templateName),
                      datasets: [
                        {
                          label: 'Messages Sent',
                          data: messageStats.map((stat) => stat.sentCount),
                          // backgroundColor: 'rgba(54, 162, 235, 0.6)',
                          backgroundColor: '#60A5FA',
                        },
                        {
                          label: 'Messages Failed',
                          data: messageStats.map((stat) => stat.failedCount),
                          // backgroundColor: 'rgba(255, 99, 132, 0.6)',
                          backgroundColor: '#F87171',
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      scales: {
                        x: {
                          grid: { display: false },
                          ticks: { font: { size: 12 }, color: '#374151' },
                        },
                        y: {
                          beginAtZero: true,
                          grid: { color: '#E5E7EB', borderDash: [5, 5] },
                          ticks: { font: { size: 12 }, color: '#374151' },
                        },
                      },
                      plugins: {
                        legend: { labels: { font: { size: 12 }, color: '#374151' } },
                        tooltip: { backgroundColor: '#111827', titleColor: '#F9FAFB', bodyColor: '#F9FAFB' },
                      },
                    }}
                  />
                </div>
              ) : (
                <p className="text-gray-500">No data available. Adjust filters and run the query.</p>
              )
            ) : (
              <p className="text-gray-500">Run a query to see the report.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageTemplateLevelReportCharts;
