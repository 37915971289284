import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faPaperPlane, faUsers, faBullhorn, faEnvelope, faCogs, faRandom, faHistory, faCalendarPlus, faBell, faClipboardList } from '@fortawesome/free-solid-svg-icons';

const LandingPageListV1 = ({ userName }) => {
  return (
    <div className="min-h-screen bg-gray-100 px-8 py-8 font-rubik">
      <div className="text-center mb-8">
        <h3 className="text-2xl font-bold mb-2">Welcome, {userName}!</h3>
        <p className="text-gray-600">Quick access to key actions and features</p>
      </div>

      <div>
        {/* Add Message Template */}
        <div className="flex items-center bg-white p-4 rounded-lg shadow hover:shadow-lg transition-shadow duration-200 mb-4">
          <FontAwesomeIcon icon={faFileAlt} className="text-custom-blue text-xl mr-4 hover:text-custom-dark-blue" />
          <div className="flex-grow">
            <h5 className="font-semibold text-md">Add Message Template</h5>
            <p className="text-gray-600 text-sm">Create new templates for your messages to use in campaigns.</p>
          </div>
          <Link
            to="/message-templates/add-template"
            className="bg-custom-blue text-white rounded-full px-4 py-2 text-sm hover:bg-custom-dark-blue transition-colors duration-200"
          >
            Create Template
          </Link>
        </div>

        {/* Send a Message */}
        <div className="flex items-center bg-white p-4 rounded-lg shadow hover:shadow-lg transition-shadow duration-200 mb-4">
          <FontAwesomeIcon icon={faPaperPlane} className="text-custom-blue text-xl mr-4 hover:text-custom-dark-blue" />
          <div className="flex-grow">
            <h5 className="font-semibold text-md">Send Message</h5>
            <p className="text-gray-600 text-sm">Send a personalized message to a single customer easily.</p>
          </div>
          <Link
            to="/message-templates/send-a-message"
            className="bg-custom-blue text-white rounded-full px-4 py-2 text-sm hover:bg-custom-dark-blue transition-colors duration-200"
          >
            Send Message
          </Link>
        </div>

        {/* Add Customer Segment */}
        <div className="flex items-center bg-white p-4 rounded-lg shadow hover:shadow-lg transition-shadow duration-200 mb-4">
          <FontAwesomeIcon icon={faUsers} className="text-custom-blue text-xl mr-4 hover:text-custom-dark-blue" />
          <div className="flex-grow">
            <h5 className="font-semibold text-md">Add Customer Segment</h5>
            <p className="text-gray-600 text-sm">Organize your customers into segments for targeted messaging.</p>
          </div>
          <Link
            to="/customer-segments-v7/add-segment"
            className="bg-custom-blue text-white rounded-full px-4 py-2 text-sm hover:bg-custom-dark-blue transition-colors duration-200"
          >
            Add Segment
          </Link>
        </div>

        {/* Additional List Items Follow the Same Pattern */}
      </div>
    </div>
  );
};

export default LandingPageListV1;
