// MessagingOverview.js
import React from 'react';
import Card from './Card';

const MessagingOverview = () => {
    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Messaging</h2>
            <div className="grid grid-cols-2 gap-4">
                <Card to="/message-templates" title="Manage Templates" description="Create, edit, and send templates" />
                {/* <Card to="/messaging/send" title="Send Message" description="Send messages to customer segments" />
                <Card to="/messaging/tracking" title="Message Tracking" description="Track delivery and read status" />
                <Card to="/messaging/webhooks" title="Webhook Responses" description="View responses from webhooks" /> */}
            </div>
        </div>
    );
};

export default MessagingOverview;
