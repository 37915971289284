import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';

const FlowSelectionModal = ({ isOpen, flows, onClose, onConfirm }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFlows, setSelectedFlows] = useState([]);

  // Filter flows based on search query
  const filteredFlows = flows.filter((flow) =>
    flow.flow_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle flow selection toggle
  const toggleTemplateSelection = (flow) => {
    setSelectedFlows((prev) =>
      prev.includes(flow)
        ? prev.filter((t) => t !== flow) // Deselect
        : [...prev, flow] // Select
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-3xl p-6">
        {/* Modal Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Select Flows</h2>
          <button
            className="text-gray-600 hover:text-gray-800"
            onClick={onClose}
          >
            ✕
          </button>
        </div>

        {/* Search Input */}
        <input
          type="text"
          placeholder="Search flows..."
          className="w-full border border-gray-300 rounded p-2 mb-4"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        {/* Grid View */}
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
          {filteredFlows.map((flow) => (
            <div
              key={flow.id}
              className={`border rounded-lg p-4 flex flex-col items-center text-center cursor-pointer ${
                selectedFlows.includes(flow)
                  ? "border-blue-500 bg-blue-100"
                  : "border-gray-300"
              }`}
              onClick={() => toggleTemplateSelection(flow)}
            >
              {/* <div className="text-3xl">{flow.icon}</div> */}
              <FontAwesomeIcon icon={faFileLines} className="text-blue-600" />
              <p className="mt-2 text-sm">{flow.flow_name}</p>
              <p className="mt-2 text-gray-400 text-[10px]">{flow.category}</p>
            </div>
          ))}
        </div>

        {/* Selected Flows Summary */}
        <div className="mt-4">
          <p className="text-sm text-gray-700">
            Selected Flows:{" "}
            <span className="font-medium">
              {selectedFlows.length > 0
                ? selectedFlows.map((t) => t.flow_name).join(", ")
                : "None"}
            </span>
          </p>
        </div>

        {/* Actions */}
        <div className="mt-6 flex justify-end">
          <button
            className="px-4 py-2 text-sm rounded-md shadow-sm bg-gray-200 rounded hover:bg-gray-300 mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 text-sm rounded-md shadow-sm bg-blue-600 text-white rounded hover:bg-blue-700"
            onClick={() => {
              onConfirm(selectedFlows); // Pass selected flows to parent
              onClose();
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default FlowSelectionModal;
