// Card.js
import React from 'react';
import { Link } from 'react-router-dom';

const Card = ({ to, title, description }) => {
    return (
        <Link to={to} className="block p-4 bg-white rounded-lg shadow-md hover:shadow-lg hover:bg-gray-50 transition">
            <h3 className="text-xl font-semibold mb-2 text-gray-800">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </Link>
    );
};

export default Card;
