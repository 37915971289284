import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import CustomBreadcrumbs from './CustomBreadcrumbs';

const MessageTemplateForm = () => {
  const [templateName, setTemplateName] = useState("");
  const [category, setCategory] = useState("");
  const [headerType, setHeaderType] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [headerMedia, setHeaderMedia] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [footerText, setFooterText] = useState("");
  const [buttons, setButtons] = useState("");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const navigate = useNavigate();

  const handleInputChange = (setStateFn) => (e) => {
    setStateFn(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const templateData = {
      template_name: templateName,
      message_category: category,
      header_type: headerType,
      header_text: headerText,
      header_media: headerMedia,
      body_text: bodyText,
      footer_text: footerText,
      buttons: buttons,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/templates`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(templateData),
      });

      if (response.ok) {
        const result = await response.json();
        toast.success(result.message);
        setTimeout(() => {
            navigate("/message-templates");  // Redirect to MessageTemplates screen
        }, 2000);
      } else {
        const errorResult = await response.json();
        toast.error(`Error: ${errorResult.message}`);  // Show error message
      }
    } catch (error) {
      console.error("Error submitting template:", error);
      toast.error("An error occurred while submitting the template.");
    }
  };


  const renderHeaderContent = () => {
    if (headerType === "IMAGE" && headerMedia) {
      return <img src={headerMedia} alt="header" className="w-full rounded-lg" />;
    }
    if (headerType === "VIDEO" && headerMedia) {
      return <video src={headerMedia} controls className="w-full rounded-lg" />;
    }
    if (headerType === "TEXT" && headerText) {
      return <p className="font-bold text-left">{headerText}</p>;
    }
    return null;
  };

  const renderButtons = () => {
    return buttons
      .split(",")
      .map((btn, index) => (
        <div
          key={index}
          className="bg-blue-600 text-white py-2 px-4 rounded mt-2 cursor-pointer text-center"
        >
          {btn.trim()}
        </div>
      ));
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6 font-rubik">
      {/* <CustomBreadcrumbs /> */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Form Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-6">
            {templateName ? "Edit" : "Create"} Message Template
          </h2>

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label className="block text-sm font-medium text-gray-700">Template Name *</label>
              <input
                type="text"
                value={templateName}
                onChange={handleInputChange(setTemplateName)}
                className="mt-1 block w-full rounded-md p-1 border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder="Enter template name"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Message Category *</label>
              <select
                value={category}
                onChange={handleInputChange(setCategory)}
                className="mt-1 block w-full rounded-md p-1 border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              >
                <option value="">Select Category</option>
                <option value="MARKETING">Marketing</option>
                <option value="UTILITIES">Utilities</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Header Type *</label>
              <select
                value={headerType}
                onChange={handleInputChange(setHeaderType)}
                className="mt-1 block w-full rounded-md p-1 border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              >
                <option value="">Select Header Type</option>
                <option value="TEXT">Text</option>
                <option value="IMAGE">Image</option>
                <option value="VIDEO">Video</option>
              </select>
            </div>

            {/* Conditional fields based on header type */}
            {headerType === "TEXT" && (
              <div>
                <label className="block text-sm font-medium text-gray-700">Header Text</label>
                <input
                  type="text"
                  value={headerText}
                  onChange={handleInputChange(setHeaderText)}
                  className="mt-1 block w-full rounded-md p-1 border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Enter header text"
                />
              </div>
            )}

            {(headerType === "IMAGE" || headerType === "VIDEO") && (
              <div>
                <label className="block text-sm font-medium text-gray-700">Header Media URL</label>
                <input
                  type="text"
                  value={headerMedia}
                  onChange={handleInputChange(setHeaderMedia)}
                  className="mt-1 block w-full rounded-md p-1 border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Enter media URL"
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700">Body Text *</label>
              <textarea
                value={bodyText}
                onChange={handleInputChange(setBodyText)}
                className="mt-1 block w-full rounded-md p-1 border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder="Enter body text"
                rows="4"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Footer Text</label>
              <input
                type="text"
                value={footerText}
                onChange={handleInputChange(setFooterText)}
                className="mt-1 block w-full rounded-md p-1 border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder="Enter footer text"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Buttons (comma-separated)</label>
              <input
                type="text"
                value={buttons}
                onChange={handleInputChange(setButtons)}
                className="mt-1 block w-full rounded-md p-1 border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder="Enter button labels"
              />
            </div>

            <div className="mt-6">
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none"
              >
                {templateName ? "Update Template" : "Create Template"}
              </button>
            </div>
          </form>
        </div>

        {/* Preview Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-4">Message Preview</h3>
          <div className="p-4 bg-gray-50 border border-gray-200 rounded-lg shadow-md">
            <div className="mb-2">
              {renderHeaderContent()}
            </div>
            <div className="mb-4 text-sm text-gray-800 whitespace-pre-wrap">
              {bodyText}
            </div>
            {footerText && (
              <div className="text-xs text-gray-500 mb-2">
                {footerText}
              </div>
            )}
            <div className="flex flex-col">{renderButtons()}</div>
          </div>
        </div>
      </div>

      {/* Toast container */}
      <ToastContainer />
    </div>
  );
};

export default MessageTemplateForm;
