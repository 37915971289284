import React, { useState, useEffect, useRef } from 'react';
import CustomBreadcrumbs from './CustomBreadcrumbs';
import Spinner from './Spinner';
import { Link } from 'react-router-dom';

const MessageTemplatesNew = () => {
  const [templates, setTemplates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openDropdown, setOpenDropdown] = useState(null);  // Track which dropdown is open
  const [loading, setLoading] = useState(true);
  const dropdownRef = useRef(null);  // Reference for dropdown
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem('token');
  const [error, setError] = useState('');

  const perPage = 4;

  useEffect(() => {
    fetchTemplates();
  }, [currentPage, searchTerm]);

  useEffect(() => {
    // Close dropdown if clicked outside
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.closest('a')) {
        setOpenDropdown(null);  // Close dropdown if click is outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      // Fetch templates from API
      const response = await fetch(`${API_BASE_URL}/api/message_templates?page=${currentPage}&search=${searchTerm}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch templates.');
      }
      const data = await response.json();
      // console.log("data:", data);
      setTemplates(data.templates);
      setTotalPages(data.templates.length > 0 ? data.total_pages : 1);
      setLoading(false); // Set loading to false after fetch
    } catch (err) {
      // setError("Failed to fetch templates. Error: ", err.message);
      setError("Failed to fetch templates.");
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const toggleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);  // Toggle dropdown open/close
  };

  return (
    // <div className="container mx-auto bg-gray-100 p-6 font-rubik">
    <div className="min-h-screen bg-gray-50 p-6 font-rubik">
      <CustomBreadcrumbs />
      {/* Header with Title and Search */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Message Templates</h2>
        <div className="flex items-center">
          <input
            type="search"
            className="border p-2 rounded-lg"
            placeholder="Search by Name"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {/* <button className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600">Search</button> */}
        </div>
      </div>

      {loading ? (
        <Spinner text="Fetching message templates..." /> // Show spinner when loading is true
      ) : error ? (
        <div className="text-center text-red-500 bg-red-100 p-4 rounded">
          <p className="text-sm">{error}</p>
        </div>
      ) : (
        <div>
          {/* Template Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
            {templates.map((template) => (
              <div key={template.id} className="bg-white rounded-lg shadow-md p-4">
                <div className="flex justify-between items-center mb-3">
                  <h4 className="font-medium">{template.name}</h4>
                  <div className="text-sm">
                    {template.status === 'APPROVED' && (
                      <span className="bg-green-200 text-green-800 px-2 py-1 rounded">Approved</span>
                    )}
                    {template.status === 'Created' && (
                      <span className="bg-blue-200 text-blue-800 px-2 py-1 rounded">Created</span>
                    )}
                    {template.status === 'Updated' && (
                      <span className="bg-yellow-200 text-yellow-800 px-2 py-1 rounded">Updated</span>
                    )}
                  </div>
                </div>

                <div className="bg-gray-50 p-3 rounded-lg">
                  {/* Message Preview */}
                  <div className="mb-2">
                    {template.header_format === 'IMAGE' && (
                      <img src={template.header_example} alt="header" className="rounded-lg" />
                    )}
                    {template.header_format === 'VIDEO' && (
                      <video src={template.header_example} controls className="rounded-lg"></video>
                    )}
                    {template.header_format === 'TEXT' && (
                      <p className="font-medium">{template.header_text}</p>
                    )}
                  </div>
                  <p className="mb-4 text-sm text-gray-700 whitespace-pre-wrap">{template.body_text}</p>
                  <p className="text-xs text-gray-500 mt-2">{template.footer_text}</p>
                </div>

                {/* Actions Dropdown */}
                <div className="flex justify-end mt-4">
                  <div className="relative inline-block text-left" ref={dropdownRef}>
                    <button onClick={() => toggleDropdown(template.id)} className="flex items-center justify-center w-full px-2 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
                      Actions
                      <svg
                        className="w-5 h-5 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14M12 5l7 7-7 7" />
                      </svg>
                    </button>

                    {openDropdown === template.id && (
                      <div className="absolute right-0 mt-2 w-48 origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                        <div className="py-1">
                          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                            Edit
                          </a>
                          <Link to="/message-templates/approval" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                            Send to WhatsApp
                          </Link>
                          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                            Refresh Status
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          <div className="flex justify-center items-center mt-6">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className={`${currentPage === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'} px-4 py-2 rounded-md mr-2`}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-gray-700">Page {currentPage} of {totalPages}</span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className={`${currentPage === totalPages ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'} px-4 py-2 rounded-md ml-2`}
              disabled={currentPage === totalPages || templates.length === 0}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageTemplatesNew;
