import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CustomBreadcrumbs from './CustomBreadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MessageDetailsModal from './MessageDetailsModal';

const ScheduledMessagesCard = () => {
  const [scheduledMessages, setScheduledMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const messagesPerPage = 10;
  const [selectedMessage, setSelectedMessage] = useState(null);

  const openMessageDetails = async (messageId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/scheduled_messages/${messageId}`);
      setSelectedMessage(response.data);
    } catch (error) {
      console.error("Error fetching message details:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchScheduledMessages = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/scheduled_messages`, {
        params: { page, per_page: messagesPerPage }
      });
      setScheduledMessages(response.data.scheduled_messages);
      setTotalPages(response.data.pages);
    } catch (error) {
      setError("Failed to load scheduled messages.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchScheduledMessages(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="p-6 max-w-6xl mx-auto bg-gray-100 min-h-screen font-rubik">
      <CustomBreadcrumbs />
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-700">Scheduled Messages</h2>
        <a
          href="/schedule-message"
          title="Schedule Message"
          className="text-black-600 hover:text-blue-600 flex items-center px-4 py-2 rounded hover:bg-blue-100"
          >
          <FontAwesomeIcon icon={faPlus} className="h-5 w-5 mr-2" />
          <span className="font-semibold text-lg">Schedule</span>
        </a>
      </div>

      {loading ? (
        <div className="text-center text-gray-500">Loading...</div>
      ) : error ? (
        <div className="text-center text-red-500">{error}</div>
      ) : (
        <div>
          <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {scheduledMessages.length > 0 ? (
                scheduledMessages.map((message) => (
                  <div key={message.id} className="p-4 bg-white rounded-lg shadow-md flex flex-col justify-between">
                    <div>
                      <h3 className="text-lg font-semibold text-gray-800 mb-2">{message.template_name}</h3>
                      <p className="text-sm text-gray-600 mb-1">
                          <span className="font-medium text-gray-700">Segment:</span> {message.segment_name}
                      </p>
                      <p className="text-sm text-gray-600 mb-1">
                          <span className="font-medium text-gray-700">Scheduled:</span> {new Date(message.send_time).toLocaleString()}
                      </p>
                      <p className="text-sm text-gray-600 mb-4">
                          <span className="font-medium text-gray-700">Created At:</span> {new Date(message.created_at).toLocaleString()}
                      </p>
                      </div>
                      <div className="flex justify-between items-center">
                      <span className={`px-3 py-1 rounded-full text-xs font-semibold ${getStatusColor(message.status)}`}>
                          {message.status.charAt(0).toUpperCase() + message.status.slice(1)}
                      </span>
                      <button
                          className="text-sm text-blue-500 font-semibold focus:outline-none"
                          onClick={() => openMessageDetails(message.id)}
                      >
                          View Details
                      </button>
                    </div>
                  </div>
                ))
            ) : (
                <p className="text-center text-gray-500 col-span-full">No scheduled messages found.</p>
            )}
            {selectedMessage && (
              <MessageDetailsModal
                isOpen={!!selectedMessage}
                onClose={() => setSelectedMessage(null)}
                messageDetails={selectedMessage}
              />
            )}
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-8">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-lg ${currentPage === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
            >
              Previous
            </button>
            <p className="text-gray-600">
              Page {currentPage} of {totalPages}
            </p>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-lg ${currentPage === totalPages ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// Helper function to get color based on status
const getStatusColor = (status) => {
  switch (status) {
    case "pending":
      return "bg-yellow-500 text-white";
    case "sent":
      return "bg-green-500 text-white";
    case "failed":
      return "bg-red-500 text-white";
    default:
      return "bg-gray-500 text-white";
  }
};

export default ScheduledMessagesCard;
