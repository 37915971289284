import React from 'react';

export default function MessageTemplatesPage() {
  return (
    <div className="min-h-screen max-w-7xl mx-auto bg-gray-50 p-6 font-rubik">
      {/* Header Section */}
      <header className="mb-6">
        <h1 className="text-2xl font-semibold text-gray-800 mb-2">Message Templates</h1>
        <p className="text-gray-600">
          Create, manage, and track your message templates for customer engagement.
        </p>
      </header>

      {/* Search and Action Section */}
      <div className="flex items-center justify-between mb-6">
        {/* Search Bar */}
        <div className="flex items-center">
          <input
            type="search"
            placeholder="Search templates..."
            className="w-full max-w-sm px-4 py-2 border rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {/* Tabs and Action Button */}
        <div className="flex items-center gap-4">
          {/* Tabs */}
          <div className="hidden sm:flex gap-2">
            <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100">
              All Templates
            </button>
            <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100">
              Approved
            </button>
            <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100">
              Pending Review
            </button>
            <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100">
              Rejected
            </button>
          </div>

          {/* New Template Button */}
          <button className="px-4 py-2 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700">
            + New Template
          </button>
        </div>
      </div>

      {/* Template List/Table */}
      <div className="bg-white shadow rounded-md">
        <table className="w-full text-left table-auto">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-gray-700">Template Name</th>
              <th className="px-6 py-3 text-gray-700">Status</th>
              <th className="px-6 py-3 text-gray-700">Last Modified</th>
              <th className="px-6 py-3 text-gray-700">Actions</th>
            </tr>
          </thead>
          <tbody>
            {/* Example Row */}
            <tr className="border-b hover:bg-gray-50">
              <td className="px-6 py-4">Welcome Message</td>
              <td className="px-6 py-4">
                <span className="px-3 py-1 text-sm text-green-600 bg-green-100 rounded-full">
                  Approved
                </span>
              </td>
              <td className="px-6 py-4">2024-11-25</td>
              <td className="px-6 py-4">
                <button className="text-blue-600 hover:underline">Edit</button>
                <button className="ml-4 text-gray-600 hover:underline">Duplicate</button>
                <button className="ml-4 text-red-600 hover:underline">Delete</button>
              </td>
            </tr>
            {/* Add more rows dynamically */}
          </tbody>
        </table>
        {/* No Templates Placeholder */}
        <div className="p-6 text-center text-gray-600">
          No templates found. Click <span className="text-blue-600 cursor-pointer">"New Template"</span> to create one.
        </div>
      </div>
    </div>
  );
}
