// QuickActions.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPaperPlane, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';

const QuickActions = () => {
  const actions = [
    { id: 1, label: "Send a Message", icon: faEnvelope, link: "/message-templates/send-a-message" },
    { id: 2, label: "Send a Flow", icon: faPaperPlane, link: "/flows/send-a-flow" },
    // { id: 3, label: "View Reports", icon: faClipboardList, link: "/reports" },
    { id: 4, label: "Insights", icon: faLightbulb, link: "/insights" },
  ];

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 font-rubik">
      <h3 className="text-lg font-semibold mb-4">Quick Actions</h3>
      
      {/* Grid layout for the actions */}
      <div className="grid grid-cols-3 gap-4">
        {actions.map((action) => (
          <Link 
            to={action.link}
            key={action.id} 
            className="bg-blue-50 hover:bg-blue-100 text-blue-600 p-4 rounded-lg flex flex-col items-center justify-center cursor-pointer transition-all duration-200 ease-in-out transform hover:scale-105"
          >
            <FontAwesomeIcon icon={action.icon} className="text-2xl mb-2" />
            <span className="text-sm font-medium text-center">{action.label}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default QuickActions;
