import React, { useState, useEffect } from 'react';
import CustomBreadcrumbs from './CustomBreadcrumbs';

const AddCustomerSegment = () => {
  const [segmentName, setSegmentName] = useState('');
  const [segmentDescription, setSegmentDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [uploadMessage, setUploadMessage] = useState('');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem('token');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log("Updated selectedFile in useEffect:", selectedFile);
  }, [selectedFile]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension === 'csv') {
        setSelectedFile(file);
        setUploadMessage('');
      } else {
        setSelectedFile(null);
        setUploadMessage('Please upload a valid CSV file.');
      }
    }
  };

  const handlePreview = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      fetch(`${API_BASE_URL}/api/preview_csv`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setPreviewData(data.preview || []);
          setUploadMessage(data.message || 'Preview generated.');
        })
        .catch(() => {
          setUploadMessage('Failed to preview the file. Please try again.');
        });
    } else {
      setUploadMessage('No file selected. Please choose a CSV file.');
    }
  };

  const handleUpload = () => {
    if (selectedFile && segmentName && segmentDescription) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      fetch(
        `${API_BASE_URL}/api/upload_csv?segment_name=${encodeURIComponent(segmentName)}&segment_description=${encodeURIComponent(segmentDescription)}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: formData,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setUploadMessage(data.message || 'File uploaded successfully!');
          setSelectedFile(null);
          setSegmentName('');
          setSegmentDescription('');
          setPreviewData([]);
        })
        .catch(() => {
          setUploadMessage('Failed to upload the file. Please try again.');
        });
    } else {
      setUploadMessage('Please fill out all fields and select a file.');
    }
  };

  return (
    <div className="min-h-screen p-6 bg-gray-100 font-rubik">
      <CustomBreadcrumbs />
      <div className="flex flex-col justify-start items-center">
        <div className="w-full max-w-3xl bg-white shadow-lg rounded-lg p-6">
          <h2 className="text-3xl font-bold text-gray-800 mb-2">Add Customer Segment</h2>
          <p className="text-gray-600 mb-6">Upload a CSV file containing customer details to create a new segment.</p>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Segment Name</label>
            <input
              type="text"
              value={segmentName}
              onChange={(e) => setSegmentName(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder="Enter segment name"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Segment Description</label>
            <input
              type="text"
              value={segmentDescription}
              onChange={(e) => setSegmentDescription(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder="Enter segment description"
            />
          </div>

          <div className="mb-4">
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="hidden"
              id="csvUpload"
            />
            <label
              htmlFor="csvUpload"
              className="block w-full p-4 border-2 border-dashed border-gray-300 text-center rounded-lg cursor-pointer text-gray-500 hover:bg-gray-50"
            >
              {selectedFile ? (
                <span className="text-green-600 font-medium">{selectedFile.name}</span>
              ) : (
                'Drag & drop your CSV file here, or click to select'
              )}
            </label>
          </div>

          <div className="flex space-x-4 mb-6">
            <button
              onClick={handlePreview}
              className="flex-1 bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
            >
              Preview
            </button>
            <button
              onClick={handleUpload}
              className="flex-1 bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
            >
              Upload
            </button>
          </div>

          {previewData.length > 0 && uploadMessage.indexOf('success') === -1 && (
            <div className="mt-6 overflow-x-auto">
              <h3 className="text-lg font-semibold mb-2">Preview of Uploaded Data:</h3>
              <table className="min-w-full bg-white border border-gray-300">
                <thead>
                  <tr>
                    {Object.keys(previewData[0]).map((header, index) => (
                      <th key={index} className="px-4 py-2 bg-gray-100 border-b font-medium text-gray-700 text-left">
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {previewData.map((row, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      {Object.values(row).map((value, idx) => (
                        <td key={idx} className="border-t px-4 py-2 text-sm text-gray-600">
                          {value}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {uploadMessage && (
            <p className={`mt-4 text-center font-medium ${uploadMessage.includes('success') ? 'text-green-600' : 'text-red-600'}`}>
              {uploadMessage}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddCustomerSegment;
