import React from 'react';

const Spinner = ({ text = "Loading, please wait..." }) => (
  <div className="flex flex-col justify-center items-center space-y-2">
    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-blue-500 border-t-transparent">
    </div>

    <p className="text-gray-600 text-sm">{text}</p>
  </div>
);

export default Spinner;
