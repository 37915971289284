import React from 'react';

const TopBar = () => {
    return (
    <div className="bg-white px-10 py-4 border-b border-gray-300 flex justify-between items-center">
        <div className="flex items-center space-x-4">
            <a href="/dashboard" className="text-gray-700 font-bold">Dashboard</a>
            <span>/</span>
            <span className="text-gray-600">Settings</span>
        </div>
        <div className="flex space-x-4">
            <a href="/profile" className="text-gray-600">Profile</a>
            <a href="/account" className="text-gray-600">Account</a>
            {/* Add links as needed */}
        </div>
    </div>
    )
}

export default TopBar;
