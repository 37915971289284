import React, { useState, useEffect } from 'react';
import CustomBreadcrumbs from './CustomBreadcrumbs';
import FlowList from './FlowList';
import axios from 'axios';
import Spinner from './Spinner';

const Flows = () => {
    const [flows, setFlows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem('token');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        axios.get(`${API_BASE_URL}/api/flows`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setFlows(response.data.flows);
            setLoading(false);
        })
        .catch(error => {
            setError('Failed to fetch flows.');
            setLoading(false);
        });
    }, []);

    // if (loading) return <div className="text-center text-gray-500">Loading...</div>;
    // if (error) return <div className="text-red-500 text-center">{error}</div>;

    return (
        <div className="p-6 min-h-screen bg-gray-50 font-rubik">
            <CustomBreadcrumbs />
            <h3 className="text-2xl font-bold mb-4">Flows</h3>
            {loading ? (
                <Spinner text="Fetching flows..." />
            ) : error ? (
                <div className="text-center text-red-500 bg-red-100 p-4 rounded">
                  <p className="text-sm">{error}</p>
                </div>
            ) : (
                <FlowList flows={flows} />
            )}
        </div>
    );
};

export default Flows;
