import React from 'react';

const MessageDetailsSlider = ({ isOpen, onClose, messageDetails }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "bg-yellow-500 text-white";
      case "sent":
        return "bg-green-500 text-white";
      case "failed":
        return "bg-red-500 text-white";
      default:
        return "bg-gray-500 text-white";
    }
  };
  return (
    <div
      className={`fixed inset-y-0 right-0 transform ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      } transition-transform duration-300 ease-in-out bg-white shadow-lg w-full max-w-md z-50`}
    >
      <div className="p-6 h-full flex flex-col">
        {/* Close Button */}
        <button
          className="self-end mb-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={onClose}
        >
          Close
        </button>

        {/* Header Section with Message Summary */}
        <div className="mb-4 border-b pb-4 space-y-2">
          <h2 className="text-2xl font-semibold text-gray-800">{messageDetails.template_id}</h2>
          <p className="text-sm text-gray-600">
            <span className="font-medium text-gray-700">Segment:</span> {messageDetails.segment_id}
          </p>
          <p className="text-sm text-gray-600">
            <span className="font-medium text-gray-700">Scheduled:</span> {new Date(messageDetails.send_time).toLocaleString()}
          </p>
          <p className="text-sm text-gray-600">
            <span className="font-medium text-gray-700">Created At:</span> {new Date(messageDetails.created_at).toLocaleString()}
          </p>
          <div className="flex justify-between items-center">
            <span className={`px-3 py-1 rounded-full text-xs font-semibold ${getStatusColor(messageDetails.status)}`}>
              {messageDetails.status.charAt(0).toUpperCase() + messageDetails.status.slice(1)}
            </span>
          </div>
        </div>

        {/* Customer List Section */}
        {messageDetails.status == 'sent' && messageDetails.customers && messageDetails.customers.length > 0 && (
          <div className="flex-1 overflow-y-auto">
            <h3 className="text-lg font-semibold mb-3">Customer Delivery Status</h3>
            <ul className="space-y-3">
              {messageDetails.customers.map((customer) => (
                <li
                  key={customer.id}
                  className="flex justify-between items-center border-b py-2"
                >
                  <p className="text-xs font-light text-gray-600">
                    <span className="text-sm font-medium text-gray-700">{customer.name}</span> {customer.phone_number}
                  </p>
                  <span
                    className={`px-2 py-1 rounded-full text-xs font-semibold ${
                      customer.delivery_status === 'Delivered'
                        ? 'bg-green-200 text-green-800'
                        : 'bg-red-200 text-red-800'
                    }`}
                  >
                    {customer.delivery_status}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageDetailsSlider;
