import React from 'react';
import CustomBreadcrumbs from './CustomBreadcrumbs';

const TemplateApproval = () => {

  return (
    <div className="container mx-auto p-6 font-rubik">
      <CustomBreadcrumbs />
      {/* Header with Title and Search */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Approval</h2>
      </div>
    </div>
  );
};

export default TemplateApproval;
