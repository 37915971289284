import React, { useState, useEffect } from "react";
import CustomBreadcrumbs from './CustomBreadcrumbs';

const ViewSentMessagesV2 = () => {
  const [messages, setMessages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const messagesPerPage = 5;

  // Fetch messages from API on component mount
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch("/api/view_sent_messages");
        if (!response.ok) {
          throw new Error("Failed to fetch messages");
        }
        const data = await response.json();
        setMessages(data.messages); // Update with data from API
        setFilteredMessages(data.messages); // Initialize filteredMessages
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, []);

  useEffect(() => {
    const filtered = messages.filter((message) =>
      message.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      message.message_template_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredMessages(filtered);
    setCurrentPage(1);
  }, [searchQuery, messages]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginatedMessages = filteredMessages.slice(
    (currentPage - 1) * messagesPerPage,
    currentPage * messagesPerPage
  );

  return (
    <div className="min-h-screen bg-gray-100 p-6 font-rubik">
      <CustomBreadcrumbs />
      <div className="flex items-center justify-between mb-6">
        <div>
            <h2 className="text-3xl font-semibold mb-4">View Sent Messages</h2>
            <p className="text-gray-600 mb-6">Review the messages you've sent to customers.</p>
        </div>

        {/* Search and Filter Section */}
        <div className="flex items-center">
            <input
            type="search"
            placeholder="Search messages by recipient or content"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full md:w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-indigo-500"
            />
        </div>
      </div>

      {/* Messages List */}
      <div className="space-y-4">
        {paginatedMessages.map((message) => (
          <div key={message.id} className="p-4 bg-white rounded-lg shadow-md flex justify-between items-center">
            <div>
              <h4 className="text-md font-semibold text-gray-800">To: {message.customer_name} ({message.customer_phone_number})</h4>
              <p className="text-sm text-gray-500">Date: {message.message_sent_time}</p>
              <p className="text-sm text-gray-700 mt-2">{message.message_template_name}</p>
            </div>
            <div>
              <span
                className={`text-sm font-semibold py-1 px-3 rounded-lg ${
                  message.status === "Delivered"
                    ? "text-green-600 bg-green-100"
                    : message.status === "Pending"
                    ? "text-yellow-600 bg-yellow-100"
                    : "text-red-600 bg-red-100"
                }`}
              >
                {message.status}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-8">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          // className="px-4 py-2 text-sm bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
          className={`px-4 py-2 rounded-lg ${currentPage === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
        >
          Previous
        </button>
        <p className="text-gray-600">
          Page {currentPage} of {Math.ceil(filteredMessages.length / messagesPerPage)}
        </p>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === Math.ceil(filteredMessages.length / messagesPerPage)}
          // className="px-4 py-2 text-sm bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
          className={`px-4 py-2 rounded-lg ${currentPage === Math.ceil(filteredMessages.length / messagesPerPage) ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ViewSentMessagesV2;
