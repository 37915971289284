import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import NotificationFeed from './NotificationFeed';
import QuickActions from './QuickActions';
import SentMessagesFeed from './SentMessagesFeed';
import SentFlowsFeed from './SentFlowsFeed';

const Dashboard = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen max-w-6xl mx-auto bg-gray-50 flex flex-col items-center p-8 font-rubik">
      <h1 className="text-3xl font-bold mb-6">Welcome to Your Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 w-full">
        <Link to="/message-templates" className="p-4 bg-white rounded-lg shadow-lg text-center">
          <h2 className="text-xl font-semibold">Templates</h2>
          <p className="text-gray-500">Manage your message templates</p>
        </Link>
        <Link to="/customer-segments-v7" className="p-4 bg-white rounded-lg shadow-lg text-center">
          <h2 className="text-xl font-semibold">Customers</h2>
          <p className="text-gray-500">View and manage customer lists</p>
        </Link>
        <Link to="/analytics" className="p-4 bg-white rounded-lg shadow-lg text-center">
          <h2 className="text-xl font-semibold">Analytics</h2>
          <p className="text-gray-500">Track your message performance</p>
        </Link>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-12 w-full mt-8">
        <NotificationFeed />
        <QuickActions />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-12 w-full mt-8">
        <SentMessagesFeed />
        <SentFlowsFeed />
      </div>
    </div>
  );
};

export default Dashboard;
