import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomBreadcrumbs from './CustomBreadcrumbs';

const ReportsOverview = () => {
  const navigate = useNavigate();

  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const reports = [
    {
      title: 'Message Template Level Report',
      description: 'Analyze message activity at the template level.',
      path: '/reports/messages',
    },
    {
      title: 'Flow Level Report',
      description: 'Analyze the performance of message flows.',
      path: '/reports/flows',
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50 p-6 font-rubik">
      {/* <CustomBreadcrumbs /> */}
      <h2 className="text-2xl font-semibold mb-6">Reports</h2>
      <div className="grid gap-12 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
        {reports.map((report) => (
          <div
            key={report.title}
            className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg cursor-pointer text-center"
            onClick={() => navigate(report.path)}
          >
            <h3 className="text-xl font-semibold mb-2">{report.title}</h3>
            <p className="text-gray-600">{report.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportsOverview;
