import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomBreadcrumbs from './CustomBreadcrumbs';
import { useNavigate } from 'react-router-dom';
import Toast from './Toast';

const ScheduleMessage = () => {
  const [templates, setTemplates] = useState([]);
  const [segments, setSegments] = useState([]);
  const [customerSegment, setCustomerSegment] = useState('');
  const [messageTemplate, setMessageTemplate] = useState('');
  const [scheduledDatetime, setScheduledDatetime] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [error, setError] = useState(null);
  const [toast, setToast] = useState(null);
  const token = localStorage.getItem('token');

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchTemplates = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/wa_templates?status=APPROVED`);
          setTemplates(response.data.templates);
        } catch (err) {
          setError("Failed to fetch templates.");
          showToast("Failed to fetch templates.", 'error');
        } finally {
          setLoading(false);
        }
    };
    fetchTemplates();
  }, [messageTemplate]);

  useEffect(() => {
    const fetchSegments = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/segments`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setSegments(response.data.segments);
        } catch (err) {
          setError("Failed to fetch segments.");
          showToast("Failed to fetch segments.", 'error');
        } finally {
          setLoading(false);
        }
    };
    fetchSegments();
  }, [customerSegment]);

  const showToast = (message, type) => {
    setToast({ message, type });
    setTimeout(() => setToast(null), 3000);
  };

  const handleScheduleMessage = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/schedule_message`, {
        customer_segment: customerSegment,
        message_template: messageTemplate,
        scheduled_datetime: scheduledDatetime,
        additional_notes: additionalNotes
      });
      setResponseMessage(response.data.message);
      showToast(response.data.message, 'success');
      setTimeout(() => {
        navigate("/scheduled-messages-cards");
      }, 3000);
    } catch (error) {
      setResponseMessage("An error occurred. Please try again.");
      showToast(error.response.data.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen p-6 max-w-6xl mx-auto bg-gray-50 font-rubik">
      <CustomBreadcrumbs />
        <div className="p-6 max-w-lg mx-auto bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-gray-700 mb-6">Schedule a Message</h2>

            {/* {responseMessage && (
                <div className={`p-4 rounded-lg mb-4 text-sm ${responseMessage.includes('success') ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                {responseMessage}
                </div>
            )} */}

            <form onSubmit={handleScheduleMessage}>
                <label className="block mb-2 text-sm font-medium text-gray-600">Customer Segment</label>
                <select
                    className="w-full mb-4 p-2 border border-gray-300 rounded-md"
                    value={customerSegment}
                    onChange={(e) => setCustomerSegment(e.target.value)}
                    required
                    >
                        <option value="" disabled>Select a segment</option>
                        {segments.map((segment) => (
                        <option key={segment.id} value={segment.id}>
                            {segment.name}
                        </option>
                        ))}
                </select>

                <label className="block mb-2 text-sm font-medium text-gray-600">Message Template</label>
                <select
                    className="w-full mb-4 p-2 border border-gray-300 rounded-md"
                    value={messageTemplate}
                    onChange={(e) => setMessageTemplate(e.target.value)}
                    required
                    >
                    <option value="" disabled>Select a template</option>
                    {templates.map((template) => (
                    <option key={template.template_id} value={template.template_id}>
                        {template.name}
                    </option>
                    ))}
                </select>

                <label className="block mb-2 text-sm font-medium text-gray-600">Schedule Date & Time</label>
                <input
                    type="datetime-local"
                    className="w-full mb-4 p-2 border border-gray-300 rounded-md"
                    value={scheduledDatetime}
                    onChange={(e) => setScheduledDatetime(e.target.value)}
                    required
                />

                <label className="block mb-2 text-sm font-medium text-gray-600">Additional Notes (optional)</label>
                <textarea
                    className="w-full mb-4 p-2 border border-gray-300 rounded-md"
                    value={additionalNotes}
                    onChange={(e) => setAdditionalNotes(e.target.value)}
                />

                <button
                    type="submit"
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 rounded-lg transition duration-200"
                    disabled={loading}
                >
                {loading ? 'Scheduling...' : 'Schedule a Message'}
                </button>
            </form>

            {toast && <Toast message={toast.message} type={toast.type} onClose={() => setToast(null)} />}
        </div>
    </div>
  );
};

export default ScheduleMessage;
