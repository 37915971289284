import React, { useState, useEffect } from 'react';
import CustomBreadcrumbs from './CustomBreadcrumbs';

const CustomerSegmentsPage = () => {
  const [segments, setSegments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Fetch the customer segments from the backend
    const fetchSegments = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/customer_segments`);
        if (!response.ok) {
          throw new Error('Failed to fetch segments.');
        }
        const data = await response.json();
        setSegments(data.segments || []);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchSegments();
  }, [API_BASE_URL]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter segments based on search query
  const filteredSegments = segments.filter(segment =>
    segment.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-100 p-8 font-rubik">
      <CustomBreadcrumbs />
      <div className="max-w-5xl mx-auto bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">Customer Segments</h2>
        <div className="mb-4">
          <input
            type="search"
            placeholder="Search by segment name..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
          />
        </div>

        {loading ? (
          <p className="text-center text-gray-600">Loading...</p>
        ) : error ? (
          <p className="text-center text-red-600">{error}</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 border-b text-left font-medium text-gray-600">ID</th>
                  <th className="px-4 py-2 border-b text-left font-medium text-gray-600">Name</th>
                  <th className="px-4 py-2 border-b text-left font-medium text-gray-600">Description</th>
                  <th className="px-4 py-2 border-b text-left font-medium text-gray-600">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredSegments.map((segment) => (
                  <tr key={segment.id} className="hover:bg-gray-50">
                    <td className="border-t px-4 py-2 text-gray-700">{segment.id}</td>
                    <td className="border-t px-4 py-2 text-gray-700">{segment.name}</td>
                    <td className="border-t px-4 py-2 text-gray-700">{segment.description || 'N/A'}</td>
                    <td className="border-t px-4 py-2 text-gray-700">
                      <button className="text-blue-500 hover:underline mr-2">View</button>
                      <button className="text-yellow-500 hover:underline mr-2">Edit</button>
                      <button className="text-red-500 hover:underline">Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {!loading && filteredSegments.length === 0 && (
          <p className="text-center text-gray-600 mt-4">No customer segments found.</p>
        )}
      </div>
    </div>
  );
};

export default CustomerSegmentsPage;
