import React, { useState, useEffect } from 'react';

const SomeDashboard = () => {
  // State variables
  const [userName, setUserName] = useState('John');
  const [lastUploadDate, setLastUploadDate] = useState('2024-11-26');
  const [physiologicalAge, setPhysiologicalAge] = useState(30);
  const [highlights, setHighlights] = useState([]);
  const [indicators, setIndicators] = useState(['Hemoglobin', 'Glucose', 'Cholesterol']);
  const [selectedIndicator, setSelectedIndicator] = useState('Hemoglobin');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [doctorsRequests, setDoctorsRequests] = useState([]);
  const [myTests, setMyTests] = useState([]);

  // Fetch data from Flask API
  useEffect(() => {
    // Simulated data fetch
    setHighlights([
      {
        title: 'High blood sugar level',
        description: 'Blood sugar level is over the normal limit. Please, consult a physician.',
        learnMoreLink: '#',
      },
      {
        title: 'Lowered red blood cell count',
        description: 'Lowered thrombocyte count. Please, consult a physician.',
        learnMoreLink: '#',
      },
      {
        title: 'Lowered level of thrombocytes',
        description: 'Lower than normal level. Please, consult a physician.',
        learnMoreLink: '#',
      },
    ]);

    setDoctorsRequests([
      { name: 'Complete blood count' },
      { name: 'Basic metabolic panel' },
    ]);

    setMyTests([
      { date: 'December 24, 2024', age: '29 years' },
      { date: 'November 19, 2024', age: '32 years' },
    ]);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      {/* Header */}
      <header className="mb-6">
        <h1 className="text-2xl font-semibold text-gray-800">Hello, {userName}!</h1>
        <p className="text-gray-600">Last uploaded test: {lastUploadDate}</p>
      </header>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Highlights Section */}
        <div className="col-span-2 space-y-4">
          <h2 className="text-lg font-medium text-gray-800">What you should pay attention to</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
            {highlights.map((highlight, index) => (
              <div key={index} className="bg-blue-100 text-blue-700 p-4 rounded-lg shadow-md">
                <h3 className="font-semibold">{highlight.title}</h3>
                <p className="text-sm">{highlight.description}</p>
                <a
                  href={highlight.learnMoreLink}
                  className="mt-2 inline-block text-sm text-blue-600 hover:underline"
                >
                  Learn more →
                </a>
              </div>
            ))}
          </div>
        </div>

        {/* Physiological Age Section */}
        <div>
          <div className="bg-white p-6 shadow rounded-lg">
            <h2 className="text-lg font-medium text-gray-800">Physiological age</h2>
            <p className="text-4xl font-semibold text-blue-600">{physiologicalAge} years</p>
            <div className="flex items-center mt-4">
              <div className="text-center mr-4">
                <span className="block text-sm text-gray-500">4 Nov</span>
                <span className="inline-block w-4 h-4 rounded-full bg-gray-300"></span>
              </div>
              <div className="text-center mr-4">
                <span className="block text-sm text-gray-500">19 Nov</span>
                <span className="inline-block w-4 h-4 rounded-full bg-gray-300"></span>
              </div>
              <div className="text-center">
                <span className="block text-sm text-gray-500">24 Dec</span>
                <span className="inline-block w-4 h-4 rounded-full bg-blue-600"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Indicators Section */}
      <div className="mt-10 bg-white p-6 shadow rounded-lg">
        <h2 className="text-lg font-medium text-gray-800 mb-4">Indicators</h2>
        <div className="flex items-center gap-4 mb-6">
          <select
            value={selectedIndicator}
            onChange={(e) => setSelectedIndicator(e.target.value)}
            className="border border-gray-300 rounded-md px-4 py-2"
          >
            {indicators.map((indicator, index) => (
              <option key={index} value={indicator}>
                {indicator}
              </option>
            ))}
          </select>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="border border-gray-300 rounded-md px-4 py-2"
          />
          <span className="text-gray-500">to</span>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="border border-gray-300 rounded-md px-4 py-2"
          />
        </div>
        <div className="h-64 bg-gray-100 rounded-lg flex items-center justify-center">
          <p>Bar chart goes here</p>
        </div>
      </div>

      {/* Sidebar */}
      <div className="mt-10 grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div>
          <h3 className="text-lg font-medium text-gray-800">Doctors' requests</h3>
          <ul className="mt-4 space-y-2">
            {doctorsRequests.map((request, index) => (
              <li
                key={index}
                className="bg-gray-100 p-4 rounded-md flex justify-between items-center"
              >
                <span>{request.name}</span>
                <button className="text-blue-600 hover:underline">View</button>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-medium text-gray-800">My tests</h3>
          <ul className="mt-4 space-y-2">
            {myTests.map((test, index) => (
              <li
                key={index}
                className="bg-gray-100 p-4 rounded-md flex justify-between items-center"
              >
                <span>{test.date}</span>
                <span>{test.age}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SomeDashboard;
