import React, { useState, useEffect } from 'react';

const CustomerSegmentsPageV2 = () => {
  const [segments, setSegments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/customer_segments`);
        if (!response.ok) {
          throw new Error('Failed to fetch segments.');
        }
        const data = await response.json();
        setSegments(data.segments || []);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchSegments();
  }, [API_BASE_URL]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredSegments = segments.filter(segment =>
    segment.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-100 p-8 font-rubik">
      <div className="max-w-5xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">Customer Segments</h2>
        <div className="mb-4">
          <input
            type="search"
            placeholder="Search by segment name..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
          />
        </div>

        {loading ? (
          <p className="text-center text-gray-600">Loading...</p>
        ) : error ? (
          <p className="text-center text-red-600">{error}</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredSegments.map(segment => (
              <div
                key={segment.id}
                className="p-4 bg-white shadow rounded-lg hover:shadow-lg transition"
              >
                <h3 className="text-lg font-semibold text-gray-800 mb-2">{segment.name}</h3>
                <p className="text-gray-600 mb-4">{segment.description || 'No description available'}</p>
                <div className="flex justify-between items-center">
                  <button className="text-blue-500 hover:underline">View</button>
                  <button className="text-yellow-500 hover:underline">Edit</button>
                  <button className="text-red-500 hover:underline">Delete</button>
                </div>
              </div>
            ))}
          </div>
        )}

        {!loading && filteredSegments.length === 0 && (
          <p className="text-center text-gray-600 mt-4">No customer segments found.</p>
        )}
      </div>
    </div>
  );
};

export default CustomerSegmentsPageV2;
