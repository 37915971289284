import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ScheduledMessagesList = () => {
  const [scheduledMessages, setScheduledMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchScheduledMessages = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/scheduled_messages`);
        setScheduledMessages(response.data.scheduled_messages);
      } catch (error) {
        setError("Failed to load scheduled messages.");
      } finally {
        setLoading(false);
      }
    };

    fetchScheduledMessages();
  }, []);

  return (
    <div className="p-6 max-w-4xl mx-auto bg-white rounded-lg shadow-md mt-8 font-rubik">
      <h2 className="text-2xl font-semibold text-gray-700 mb-6">Scheduled Messages</h2>

      {loading ? (
        <div className="text-center text-gray-500">Loading...</div>
      ) : error ? (
        <div className="text-center text-red-500">{error}</div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b">Template Name</th>
                <th className="px-4 py-2 border-b">Segment</th>
                <th className="px-4 py-2 border-b">Scheduled Send Time</th>
                <th className="px-4 py-2 border-b">Status</th>
                <th className="px-4 py-2 border-b">Created At</th>
              </tr>
            </thead>
            <tbody>
              {scheduledMessages.length > 0 ? (
                scheduledMessages.map((message) => (
                  <tr key={message.id} className="hover:bg-gray-100">
                    <td className="px-4 py-2 border-b text-sm">{message.template_name}</td>
                    <td className="px-4 py-2 border-b text-sm">{message.segment_name}</td>
                    <td className="px-4 py-2 border-b text-sm">{new Date(message.send_time).toLocaleString()}</td>
                    <td className="px-4 py-2 border-b text-sm">
                      <span className={`px-2 py-1 rounded-lg text-white ${getStatusColor(message.status)}`}>
                        {message.status.charAt(0).toUpperCase() + message.status.slice(1)}
                      </span>
                    </td>
                    <td className="px-4 py-2 border-b text-sm">{new Date(message.created_at).toLocaleString()}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center text-gray-500 py-4">
                    No scheduled messages found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

// Helper function to get color based on status
const getStatusColor = (status) => {
  switch (status) {
    case "pending":
      return "bg-yellow-500";
    case "sent":
      return "bg-green-500";
    case "failed":
      return "bg-red-500";
    default:
      return "bg-gray-500";
  }
};

export default ScheduledMessagesList;
