import React, { useState, useEffect } from 'react';
import ReactSelect, { components } from "react-select";

// Customizing ValueContainer to fix duplicate placeholders
const ValueContainer = ({ children, getValue, ...props }) => {
  const selectedValues = getValue();
  const count = selectedValues.length;

  let summary = "Select Templates"; // Default placeholder
  if (count > 0) {
    summary = count === 1 ? selectedValues[0].label : `${count} selected`;
  }

  return (
    <components.ValueContainer {...props}>
      <span className="truncate">{summary}</span>
    </components.ValueContainer>
  );
};

// Customizing Option to keep selected options visible with checkboxes checked
const Option = ({ data, isSelected, innerRef, innerProps }) => (
  <div
    ref={innerRef}
    {...innerProps}
    className={`flex items-center px-3 py-2 cursor-pointer ${
      isSelected ? "bg-blue-100" : "hover:bg-gray-200"
    }`}
  >
    <input
      type="checkbox"
      checked={isSelected}
      onChange={() => {}}
      className="mr-3"
    />
    <label className="text-sm">{data.label}</label>
  </div>
);

// Ensuring all options are visible in the dropdown
const MenuList = (props) => {
  const { options, children, getValue } = props;
  const selectedValues = getValue();

  return (
    <components.MenuList {...props}>
      {options.map((option) => {
        const isSelected = selectedValues.some(
          (value) => value.value === option.value
        );
        return (
          <components.Option
            key={option.value}
            data={option}
            isSelected={isSelected}
            innerRef={props.innerRef}
            innerProps={props.innerProps}
          />
        );
      })}
    </components.MenuList>
  );
};

const customComponents = {
  Option,
  MultiValue: () => null, // Hides individual selected value chips
  MultiValueContainer: () => null, // Hides the multi-value container
  ValueContainer, // Custom ValueContainer for the textbox
  MenuList, // Custom MenuList to ensure visibility of all options
};

const Test = () => {
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    // Example templates (replace with API call if needed)
    setTemplates([
      { value: "template1", label: "Template 1" },
      { value: "template2", label: "Template 2" },
      { value: "template3", label: "Template 3" },
    ]);
  }, []);

  const handleTemplateChange = (selectedOptions) => {
    setSelectedTemplates(selectedOptions || []);
  };

  return (
    <ReactSelect
      options={templates}
      isMulti
      onChange={handleTemplateChange}
      value={selectedTemplates}
      placeholder="Select Templates"
      closeMenuOnSelect={false}
      components={customComponents}
      styles={{
        control: (base) => ({
          ...base,
          minHeight: "40px",
          cursor: "pointer",
        }),
        placeholder: (base) => ({
          ...base,
          color: "#6B7280",
        }),
        singleValue: (base) => ({
          ...base,
          color: "#374151",
        }),
      }}
    />
  );
};

export default Test;
