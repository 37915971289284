import React, { useState } from 'react';

const TemplateList = ({ templates }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <div>
      <ul className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
        {templates.map((template) => (
          <li
            key={template.template_id}
            className="p-4 bg-white rounded-lg shadow-md flex justify-between items-center"
          >
            <div className="font-medium">{template.name}</div>
            <div
              className={`px-2 py-1 rounded-full text-sm ${
                template.status === 'APPROVED' ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
              }`}
            >
              {template.status}
            </div>
          </li>
        ))}
        </div>
      </ul>
      {/* Pagination */}
      <div className="flex justify-center mt-6">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className="bg-gray-300 px-4 py-2 rounded-md mr-2"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className="bg-gray-300 px-4 py-2 rounded-md"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TemplateList;
