import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faPaperPlane, faUsers, faBullhorn, faEnvelope, faCogs, faRandom, faHistory, faCalendarPlus, faBell, faClipboardList } from '@fortawesome/free-solid-svg-icons';

const actions = [
  {
    title: 'Add Message Template',
    description: 'Create new templates for your messages to use in campaigns.',
    link: '/message-templates/add-template',
    icon: faFileAlt,
    buttonText: 'Create Template',
  },
  {
    title: 'Send Message',
    description: 'Send a personalized message to a single customer easily.',
    link: '/message-templates/send-a-message',
    icon: faPaperPlane,
    buttonText: 'Send Message',
  },
  {
    title: 'Add Customer Segment',
    description: 'Organize your customers into segments for targeted messaging.',
    link: '/customer-segments-v7/add-segment',
    icon: faUsers,
    buttonText: 'Add Segment',
  },
  {
    title: 'Send Message to Segment',
    description: 'Send a message to a specific customer segment.',
    link: '/send_a_message_to_segment',
    icon: faBullhorn,
    buttonText: 'Send to Segment',
  },
  {
    title: 'View Sent Messages',
    description: 'Review the messages you\'ve sent to customers.',
    link: '/history/view-sent-messages',
    icon: faEnvelope,
    buttonText: 'View Messages',
  },
  {
    title: 'Manage Templates',
    description: 'Edit, update, or delete your message templates.',
    link: '/message-templates',
    icon: faCogs,
    buttonText: 'Manage Templates',
  },
  {
    title: 'Send Flow',
    description: 'Send a personalized flow to a single customer easily.',
    link: '/flows/send-a-flow',
    icon: faRandom,
    buttonText: 'Send Flow',
  },
  {
    title: 'View Sent Flows',
    description: 'Review flows you\'ve sent to customers.',
    link: '/history/view-sent-flows',
    icon: faHistory,
    buttonText: 'View Sent Flows',
  }
];

const LandingPageListV2 = ({ userName }) => {
  return (
    <div className="min-h-screen bg-gray-100 px-8 py-8 font-rubik">
      <div className="text-center mb-8">
        <h3 className="text-2xl font-bold mb-2">Welcome, {userName}!</h3>
        <p className="text-gray-600">Quick access to key actions and features</p>
      </div>

      <div>
        {actions.map((action, index) => (
          <div
            key={index}
            className="flex items-center bg-white p-4 rounded-lg shadow hover:shadow-lg transition-shadow duration-200 mb-4"
          >
            <FontAwesomeIcon icon={action.icon} className="text-custom-blue text-xl mr-4 hover:text-custom-dark-blue" />
            <div className="flex-grow">
              <h5 className="font-semibold text-md">{action.title}</h5>
              <p className="text-gray-600 text-sm">{action.description}</p>
            </div>
            <Link
              to={action.link}
              className="bg-custom-blue text-white rounded-full px-4 py-2 text-sm hover:bg-custom-dark-blue transition-colors duration-200"
            >
              {action.buttonText}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LandingPageListV2;
