import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTachometerAlt, faUsers, faChartLine, faUserCircle, faClipboardList, faGear, faHistory, faCheckCircle, faExclamationTriangle, faInfoCircle, faWind } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faBell, faLightbulb } from '@fortawesome/free-regular-svg-icons';

const IconsSidebar = () => {
  const [isNotificationsOpen, setNotificationsOpen] = useState(false);
  const [isUserOptionsOpen, setUserOptionsOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem('token');

  const navigate = useNavigate();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.notification-dropdown') && !event.target.closest('.user-options-dropdown')) {
        setNotificationsOpen(false);
        setUserOptionsOpen(false);
      }
    };
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/recent_notifications`, {headers: {Authorization: `Bearer ${token}`}});
        if (response.status === 401) {
          const errorResponse = await response.json();
          if (errorResponse.msg && errorResponse.msg.includes("Token has expired")) {
            handleLogout();
            console.log("Token expired");
          } else {
            throw new Error("Unauthorized");
          }
        } else if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || "Failed to fetch messages");
        }
        const data = await response.json();
        // setUnreadCount(data.unread_count);
        setUnreadCount(data.unread_notifications_count);
        setNotifications(data.notifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
        setError(error.message);
      }
    };

    fetchNotifications();
  }, []);

  const toggleNotificationsDropdown = (e) => {
    e.stopPropagation();
    setNotificationsOpen(!isNotificationsOpen);
    setUserOptionsOpen(false);
  };

  const toggleUserOptionsDropdown = (e) => {
    e.stopPropagation();
    setUserOptionsOpen(!isUserOptionsOpen);
    setNotificationsOpen(false);
  };

  const getNotificationIcon = (type) => {
  switch(type) {
    case 'success':
        return <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-2" />;
    case 'alert':
        return <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500 mr-2" />;
    case 'message':
        return <FontAwesomeIcon icon={faEnvelope} className="text-blue-500 mr-2" />;
    case 'info':
    default:
        return <FontAwesomeIcon icon={faInfoCircle} className="text-blue-500 mr-2" />;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  return (
    <div className="w-20 bg-white text-gray-800 flex flex-col min-h-screen shadow-md fixed font-rubik">
      <h2 className="text-2xl font-bold text-center p-4">MC</h2>

      <nav className="flex flex-col space-y-2 p-4 items-center">
        {/* Sidebar icons with tooltips */}
        
        <NavLink
          to="/landing"
          title="Home" // Tooltip text
          className={({ isActive }) =>
            isActive ? "p-2 bg-blue-100 text-blue-600 rounded-full" : "p-2 text-gray-700 hover:bg-gray-100 rounded-full"
          }
        >
          <FontAwesomeIcon icon={faHome} className="text-xl" />
        </NavLink>

        <NavLink
          to="/dashboard"
          title="Dashboard" // Tooltip text
          className={({ isActive }) =>
            isActive ? "p-2 bg-blue-100 text-blue-600 rounded-full" : "p-2 text-gray-700 hover:bg-gray-100 rounded-full"
          }
        >
          <FontAwesomeIcon icon={faTachometerAlt} className="text-xl" />
        </NavLink>

        <NavLink
          to="/message-templates"
          title="Message Templates" // Tooltip text
          className={({ isActive }) =>
            isActive ? "p-2 bg-blue-100 text-blue-600 rounded-full" : "p-2 text-gray-700 hover:bg-gray-100 rounded-full"
          }
        >
          <FontAwesomeIcon icon={faEnvelope} className="text-xl" />
        </NavLink>

        <NavLink
          to="/customer-segments-v7"
          title="Customer Segments" // Tooltip text
          className={({ isActive }) =>
            isActive ? "p-2 bg-blue-100 text-blue-600 rounded-full" : "p-2 text-gray-700 hover:bg-gray-100 rounded-full"
          }
        >
          <FontAwesomeIcon icon={faUsers} className="text-xl" />
        </NavLink>

        <NavLink
          to="/flows"
          title="Flows" // Tooltip text
          className={({ isActive }) =>
            isActive ? "p-2 bg-blue-100 text-blue-600 rounded-full" : "p-2 text-gray-700 hover:bg-gray-100 rounded-full"
          }
        >
          <FontAwesomeIcon icon={faWind} className="text-xl" />
        </NavLink>

        <NavLink
          to="/history"
          title="History" // Tooltip text
          className={({ isActive }) =>
            isActive ? "p-2 bg-blue-100 text-blue-600 rounded-full" : "p-2 text-gray-700 hover:bg-gray-100 rounded-full"
          }
        >
          <FontAwesomeIcon icon={faHistory} className="text-xl" />
        </NavLink>

        <NavLink
          to="/analytics"
          title="Analytics" // Tooltip text
          className={({ isActive }) =>
            isActive ? "p-2 bg-blue-100 text-blue-600 rounded-full" : "p-2 text-gray-700 hover:bg-gray-100 rounded-full"
          }
        >
          <FontAwesomeIcon icon={faChartLine} className="text-xl" />
        </NavLink>

        <NavLink
          to="/reports"
          title="Reports" // Tooltip text
          className={({ isActive }) =>
            isActive ? "p-2 bg-blue-100 text-blue-600 rounded-full" : "p-2 text-gray-700 hover:bg-gray-100 rounded-full"
          }
        >
          <FontAwesomeIcon icon={faClipboardList} className="text-xl" />
        </NavLink>

        <NavLink
          to="/insights"
          title="Insights" // Tooltip text
          className={({ isActive }) =>
            isActive ? "p-2 bg-blue-100 text-blue-600 rounded-full" : "p-2 text-gray-700 hover:bg-gray-100 rounded-full"
          }
        >
          <FontAwesomeIcon icon={faLightbulb} className="text-xl" />
        </NavLink>

        <NavLink
          to="/settings-new"
          title="Settings" // Tooltip text
          className={({ isActive }) =>
            isActive ? "p-2 bg-blue-100 text-blue-600 rounded-full" : "p-2 text-gray-700 hover:bg-gray-100 rounded-full"
          }
        >
          <FontAwesomeIcon icon={faGear} className="text-xl" />
        </NavLink>
      </nav>

      {/* Bottom icons with dropdowns */}
      <div className="mt-auto p-4 relative flex flex-col items-center">
        <div className="relative mb-4">
          <button
            title="Notifications"
            className="text-gray-600 hover:text-blue-600 focus:outline-none"
            onClick={toggleNotificationsDropdown}
          >
            <FontAwesomeIcon icon={faBell} className="text-2xl" />
            {unreadCount > 0 && (
              <span className="absolute -top-3 -right-3 bg-red-500 text-white rounded-full text-sm px-1.5">
                {unreadCount}
              </span>
            )}
          </button>
          {isNotificationsOpen && (
            <div className="absolute bottom-5 left-5 w-[32rem] bg-white shadow-xl rounded-lg p-2 notification-dropdown">
              <h3 className="text-lg font-semibold text-gray-800 mb-2 border-b border-gray-200 pb-2 text-center">Notifications</h3>
              <ul className="space-y-2">
                {error ? (
                  <li className="text-gray-600 text-sm text-center">Error: {error}</li>
                ) : (
                  notifications.length > 0 ? (
                    notifications.map((notification) => (
                      <li key={notification.id} className="p-2 text-sm text-gray-700 bg-gray-50 rounded-lg hover:bg-blue-100">
                        <div className="flex items-center text-gray-700">
                            {getNotificationIcon(notification.type)}
                            <div className="block ml-2 flex-1">
                                {notification.message}
                            </div>
                        </div>
                        {/* <span className="block">{notification.message}</span> */}
                        <div className="text-xs text-gray-500 mt-1 text-right">{notification.time}</div>
                      </li>
                    ))
                  ) : (
                    <li className="text-gray-600 text-sm text-center">No new notifications</li>
                  )
                )}
              </ul>
              <div className="flex justify-around mt-3 border-t border-gray-200 pt-2">
                <NavLink
                  to="/notification-center"
                  className="block p-2 text-blue-600 text-sm font-medium hover:bg-blue-100 rounded"
                  onClick={() => setNotificationsOpen(false)}
                >
                  View All
                </NavLink>
                <button
                  className="block p-2 text-blue-600 text-sm font-medium hover:bg-blue-100 rounded cursor-pointer"
                  onClick={() => {/* Mark all as read */}}
                >
                  Mark All as Read
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="relative">
          <button
            title="User Options"
            className="text-gray-600 hover:text-blue-600 focus:outline-none"
            onClick={toggleUserOptionsDropdown}
          >
            <FontAwesomeIcon icon={faUserCircle} className="text-2xl" />
          </button>
          {isUserOptionsOpen && (
            <div className="absolute bottom-5 left-5 w-48 bg-white shadow-lg rounded-lg p-2 user-options-dropdown">
              <NavLink
                to="/users"
                className="block p-2 text-sm text-gray-700 hover:bg-blue-100 rounded"
                onClick={() => setUserOptionsOpen(false)}
              >
                Admin
              </NavLink>
              <NavLink
                to="/profile"
                className="block p-2 text-sm text-gray-700 hover:bg-blue-100 rounded"
                onClick={() => setUserOptionsOpen(false)}
              >
                Profile
              </NavLink>
              <p className="p-2 text-sm text-gray-700 hover:bg-blue-100 rounded cursor-pointer" onClick={handleLogout}>
                Logout
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IconsSidebar;
