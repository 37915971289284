import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faPaperPlane, faUsers, faBullhorn, faEnvelope, faCogs, faRandom, faHistory, faCalendarPlus, faBell, faClipboardList } from '@fortawesome/free-solid-svg-icons';

const LandingPageList = ({ userName }) => {
  return (
    <div className="min-h-screen bg-gray-100 px-8 py-8 font-rubik">
      <div className="text-center mb-8">
        <h3 className="text-2xl font-bold mb-2">Welcome, {userName}!</h3>
        <p className="text-gray-600">Quick access to key actions and features</p>
      </div>

      <div>
        {/* Add Message Template */}
        <div className="list-item">
          <FontAwesomeIcon icon={faFileAlt} className="list-icon" />
          <div className="list-content">
            <h5 className="list-title">Add Message Template</h5>
            <p className="list-description">Create new templates for your messages to use in campaigns.</p>
          </div>
          <Link to="/message-templates/add-template" className="list-button">
            Create Template
          </Link>
        </div>

        {/* Send a Message */}
        <div className="list-item">
          <FontAwesomeIcon icon={faPaperPlane} className="list-icon" />
          <div className="list-content">
            <h5 className="list-title">Send Message</h5>
            <p className="list-description">Send a personalized message to a single customer easily.</p>
          </div>
          <Link to="/message-templates/send-a-message" className="list-button">
            Send Message
          </Link>
        </div>

        {/* Add Customer Segment */}
        <div className="list-item">
          <FontAwesomeIcon icon={faUsers} className="list-icon" />
          <div className="list-content">
            <h5 className="list-title">Add Customer Segment</h5>
            <p className="list-description">Organize your customers into segments for targeted messaging.</p>
          </div>
          <Link to="/customer-segments-v7/add-segment" className="list-button">
            Add Segment
          </Link>
        </div>

        {/* Send Message to Segment */}
        <div className="list-item">
          <FontAwesomeIcon icon={faBullhorn} className="list-icon" />
          <div className="list-content">
            <h5 className="list-title">Send Message to Segment</h5>
            <p className="list-description">Send a message to a specific customer segment.</p>
          </div>
          <Link to="/send_a_message_to_segment" className="list-button">
            Send to Segment
          </Link>
        </div>

        {/* Additional List Items Follow the Same Pattern */}
      </div>
    </div>
  );
};

export default LandingPageList;
