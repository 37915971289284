import React, { useState, useEffect } from 'react';
// import { FiSearch } from 'react-icons/fi';
// import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUsers, faEdit, faTrashCan, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const CustomerSegmentsPageV6 = () => {
  const [segments, setSegments] = useState([]);
  const [currentSegments, setCurrentSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [segmentsPerPage, setSegmentsPerPage] = useState(5); // Default to 5 segments per page
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [customerPage, setCustomerPage] = useState(1);
  const customersPerPage = 5;

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/customer_segments`);
        if (!response.ok) {
          throw new Error('Failed to fetch segments.');
        }
        const data = await response.json();
        setSegments(data.segments || []);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchSegments();
  }, [API_BASE_URL]);

  // Filter and paginate segments
  useEffect(() => {
    const filteredSegments = segments.filter(segment =>
      segment.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const startIdx = (currentPage - 1) * segmentsPerPage;
    setCurrentSegments(filteredSegments.slice(startIdx, startIdx + segmentsPerPage));
  }, [segments, searchTerm, currentPage, segmentsPerPage]);

  const handlePageChange = (pageNum) => setCurrentPage(pageNum);

  const totalPages = Math.ceil(segments.filter(segment =>
    segment.name.toLowerCase().includes(searchTerm.toLowerCase())
  ).length / segmentsPerPage);

  const paginatedCustomers = selectedSegment && selectedSegment.customers ? selectedSegment.customers.slice(
    (customerPage - 1) * customersPerPage,
    customerPage * customersPerPage
  ) : [];
  
  const totalCustomerPages = selectedSegment && selectedSegment.customers ? Math.ceil(selectedSegment.customers.length / customersPerPage) : 0;

  return (
    <div className="min-h-screen bg-gray-100 flex font-rubik">
      {/* Left Panel */}
      <div className="w-1/3 bg-white p-4 border-r border-gray-300 overflow-y-auto">
        {/* Search and List */}
        <div className="flex items-center mb-4">
          {/* <FiSearch className="text-gray-400 mr-2" /> */}
          <FontAwesomeIcon icon={faSearch} className="text-gray-400 mr-2" />
          <input
            type="search"
            placeholder="Search segments..."
            className="w-full border rounded px-3 py-2"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1); // Reset to first page on search
            }}
          />
        </div>
        
        {/* Segments List */}
        <h2 className="text-xl font-semibold mb-4">Customer Segments</h2>
        {loading ? <p>Loading...</p> : error ? <p>{error}</p> : (
          <>
            <ul className="space-y-2">
              {currentSegments.map(segment => (
                <li key={segment.id} onClick={() => {
                  setSelectedSegment(segment);
                  setCustomerPage(1);
                }} className={`p-3 flex items-center cursor-pointer rounded-lg transition-all duration-200 ${selectedSegment && selectedSegment.id === segment.id ? 'bg-blue-100 border-l-4 border-blue-500' : 'hover:bg-gray-100'}`}>
                  {/* <AiOutlineUsergroupAdd className="text-blue-500 mr-3" /> */}
                  <FontAwesomeIcon icon={faUsers} className="text-blue-500 mr-3" />
                  <div>
                    <div className="text-gray-800 font-medium">{segment.name}</div>
                    <div className="text-sm text-gray-500">{segment.description || 'No description'}</div>
                    {segment.status && (
                      <span
                        className={`inline-block px-2 py-1 mt-1 text-xs font-semibold ${segment.status === 'Active' ? 'text-green-700 bg-green-100' : 'text-red-700 bg-red-100'} rounded-full`}
                      >
                        {segment.status}
                      </span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
            
            {/* Pagination Controls */}
            <div className="flex items-center justify-between mt-4">
              {/* <button disabled={currentPage === 1} onClick={() => {
                handlePageChange(currentPage - 1);
                setSelectedSegment(null);
              }} className="px-3 py-1 bg-gray-300 rounded disabled:opacity-50">Previous</button> */}
              <button 
                disabled={currentPage === 1} 
                onClick={() => {
                  handlePageChange(currentPage - 1);
                  setSelectedSegment(null);
                }} 
                className="text-gray-500 hover:text-gray-700 disabled:opacity-50"
                aria-label="Previous Page"
                title="Previous Page">
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <div className="text-sm text-gray-600">
                Page {currentPage} of {totalPages}
              </div>
              {/* <button disabled={currentPage === totalPages} onClick={() => {
                handlePageChange(currentPage + 1);
                setSelectedSegment(null);
              }} className="px-3 py-1 bg-gray-300 rounded disabled:opacity-50">Next</button> */}
              <button 
                disabled={currentPage === totalPages} 
                onClick={() => {
                  handlePageChange(currentPage + 1);
                  setSelectedSegment(null);
                }} 
                className="text-gray-500 hover:text-gray-700 disabled:opacity-50"
                aria-label="Next Page"
                title="Next Page">
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
            
            {/* Items per page */}
            <div className="mt-4">
              <label className="text-sm text-gray-600 mr-2">Items per page:</label>
              <select value={segmentsPerPage} onChange={(e) => setSegmentsPerPage(Number(e.target.value))} className="border rounded px-2 py-1">
                {[5, 10, 20].map(num => <option key={num} value={num}>{num}</option>)}
              </select>
            </div>
          </>
        )}
      </div>

      {/* Right Panel */}
      <div className="w-2/3 bg-white p-6">
        {selectedSegment ? (
          <>
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="text-2xl font-bold">{selectedSegment.name}</h3>
                <p className="text-gray-600">{selectedSegment.description || 'No description available'}</p>
              </div>
              <div className="flex">
              <button 
                className="text-blue-500 text-lg p-2 mr-2 hover:text-blue-600" 
                title="Edit"
                aria-label="Edit">
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button 
                className="text-red-500 text-lg p-2 hover:text-red-600" 
                title="Delete"
                aria-label="Delete">
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
              </div>
            </div>
            <div className="mt-4">
              <strong>Details</strong>
              <div className="flex justify-between items-center">
                <p>Created At: {new Date(selectedSegment.created_at).toLocaleString()}</p>
                <p>Updated At: {new Date(selectedSegment.updated_at).toLocaleString()}</p>
              </div>
              {selectedSegment && selectedSegment.customers && (<p>Number of Customers: {selectedSegment.customers.length}</p>)}
            </div>
            {/* Display Customer Details */}
            <div className="mt-4">
              <h4 className="text-xl font-semibold">Customers</h4>
              <ul className="mt-2">
                {paginatedCustomers.map((customer, index) => (
                  <li key={index} className="py-2 border-b hover:bg-blue-100">
                    <p className="text-gray-800 font-medium">{customer.name}</p>
                    <p className="text-gray-500 text-sm">Phone: {customer.phone_number}</p>
                  </li>
                ))}
              </ul>

              {/* Customer Pagination Controls */}
              {selectedSegment && (
                <div className="flex items-center justify-between mt-4">
                  <button 
                    disabled={customerPage === 1} 
                    onClick={() => setCustomerPage(customerPage - 1)} 
                    className="text-gray-500 hover:text-gray-700 disabled:opacity-50"
                    aria-label="Previous Page"
                    title="Previous Page">
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <div className="text-sm text-gray-600">
                    Page {customerPage} of {totalCustomerPages}
                  </div>
                  <button 
                    disabled={customerPage === totalCustomerPages} 
                    onClick={() => setCustomerPage(customerPage + 1)} 
                    className="text-gray-500 hover:text-gray-700 disabled:opacity-50"
                    aria-label="Next Page"
                    title="Next Page">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <p className="text-gray-500 text-center">Select a customer segment to see the details.</p>
        )}
      </div>
    </div>
  );
};

export default CustomerSegmentsPageV6;
