const Toast = ({ message, type, onClose }) => {
    const backgroundColor = type === 'success' ? 'bg-green-100' : type === 'error' ? 'bg-red-100' : 'bg-gray-100';
    const textColor = type === 'success' ? 'text-green-800' : type === 'error' ? 'text-red-800' : 'text-gray-800';
  
    return (
        <div
            className={`fixed top-10 right-10 ${backgroundColor} ${textColor} px-4 py-2 rounded-md shadow-2xl flex items-center justify-between`}
            style={{ minWidth: '250px', maxWidth: '400px', height: 'auto' }}
        >
            <span className="break-words">{message}</span>
            <button
                onClick={onClose}
                className="ml-4 rounded-full px-2 py-1 focus:outline-none"
            >
                ✕
            </button>
      </div>
    );
};

export default Toast;
