import React, { useState, useEffect } from 'react';
import CustomBreadcrumbs from './CustomBreadcrumbs';
import Spinner from './Spinner';

const FlowLevelReport = () => {
  const [messageStats, setMessageStats] = useState([]);
  const [dateRange, setDateRange] = useState('this_month');
  const [selectedFlow, setSelectedFlow] = useState('');
  const [flows, setFlows] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateError, setDateError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasQueried, setHasQueried] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchFlows = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/flows?status=PUBLISHED`);
        if (!response.ok) throw new Error('Failed to fetch flows');
        const data = await response.json();
        setFlows(data.flows);
      } catch (error) {
        console.error('Error fetching flows:', error);
      }
    };

    fetchFlows();
  }, []);

  useEffect(() => {
    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      setDateError('Start date cannot be after the end date.');
    } else {
      setDateError('');
    }
  }, [startDate, endDate]);

  const fetchReportData = async () => {
    if (dateError) return;
    setIsLoading(true);
    setHasQueried(true);
    try {
      let url = `${API_BASE_URL}/api/flow_reports?flow=${selectedFlow}`;
      if (dateRange === 'custom_range' && startDate && endDate) {
        url += `&dateRange=custom_range&startDate=${startDate}&endDate=${endDate}`;
      } else {
        url += `&dateRange=${dateRange}`;
      }
      // const response = await fetch(
      //   `${API_BASE_URL}/api/message_reports?dateRange=${dateRange}&template=${selectedFlow}`
      // );
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch report data');
      const data = await response.json();
      setMessageStats(data.flow_stats);
    } catch (error) {
      console.error('Error fetching report data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isRunQueryDisabled = dateRange === 'custom_range' && (!startDate || !endDate || dateError);

  return (
    <div className="min-h-screen bg-gray-100 font-rubik flex">
      {/* Side Panel */}
      <div className="w-1/5 p-4 bg-white rounded-lg shadow-md mr-6">
        <h3 className="text-xl font-semibold mb-4">Filters</h3>
        <label className="block mb-2 font-medium">Date Range</label>
        <select
          className="p-2 border rounded-md w-full mb-4"
          value={dateRange}
          onChange={(e) => setDateRange(e.target.value)}
        >
          <option value="this_month">This Month</option>
          <option value="last_month">Last Month</option>
          <option value="custom_range">Custom Range</option>
        </select>

        {dateRange === "custom_range" && (
          <div>
            <label className="block mb-2 font-medium">Start Date:</label>
            <input className="p-2 border rounded-lg focus:outline-none w-full mb-4" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            {dateError && (
              <p className="text-red-600 mb-2">{dateError}</p>
            )}
            <label className="block mb-2 font-medium">End Date:</label>
            <input className="p-2 border rounded-lg focus:outline-none w-full mb-4" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
          </div>
        )}

        <label className="block mb-2 font-medium">Template</label>
        <select
          className="p-2 border rounded-md w-full"
          value={selectedFlow}
          onChange={(e) => setSelectedFlow(e.target.value)}
        >
          <option value="">All Flows</option>
          {flows.map((flow) => (
            <option key={flow.id} value={flow.flow_name}>
              {flow.flow_name}
            </option>
          ))}
        </select>

        <button
          className={`mt-4 w-full px-4 py-2 font-semibold rounded-md shadow-sm ${
            isRunQueryDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-700'
          }`}
          onClick={fetchReportData}
          disabled={isRunQueryDisabled}
        >
          Run Query
        </button>
      </div>

      <div className="flex-1">
        <CustomBreadcrumbs />
        <h2 className="text-3xl font-semibold mb-4">Flow Level Report</h2>
        <p className="text-gray-600 mb-6">Analyze flow activity based on selected filters.</p>

        {isLoading ? (
          <Spinner text="Fetching the report..." />
        ) : (
          <div>
            {hasQueried ? (
              messageStats.length > 0 ? (
                <div className="grid gap-6">
                  {messageStats.map((stat) => (
                    <div key={stat.flowId} className="p-4 bg-white rounded-lg shadow-md">
                      <div className="flex justify-between items-center mb-2">
                        <h4 className="text-lg font-semibold text-gray-800">{stat.flowName}</h4>
                        <span
                          className={`px-2 py-1 text-xs font-semibold rounded-md ${
                            stat.successRate >= 100
                              ? 'bg-green-100 text-green-600'
                              : stat.successRate >= 80
                              ? 'bg-yellow-100 text-yellow-600'
                              : 'bg-red-100 text-red-600'
                          }`}
                        >
                          {stat.successRate.toFixed(2)}%
                        </span>
                      </div>
                      <div className="relative h-3 rounded-full bg-gray-200">
                        <div
                          className="absolute top-0 left-0 h-3 rounded-full"
                          style={{
                            width: `${Math.min(stat.successRate, 100)}%`,
                            backgroundColor:
                              stat.successRate >= 100
                                ? '#34D399'
                                : stat.successRate >= 80
                                ? '#FBBF24'
                                : '#F87171',
                          }}
                        />
                      </div>
                      <div className="flex justify-between text-sm mt-2 text-gray-700">
                        <span>{`${stat.deliveredCount.toLocaleString()} sent / ${stat.goalCount.toLocaleString()} total`}</span>
                        <span>{stat.successRate >= 100 ? 'Sent to all successfully!' : ''}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500">No data available. Adjust filters and run the query.</p>
              )
            ) : (
              <p className="text-gray-500">Run a query to see the report.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FlowLevelReport;
