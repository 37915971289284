// Import React and necessary hooks
import React, { useState, useEffect } from "react";
import CustomBreadcrumbs from './CustomBreadcrumbs';
import Spinner from "./Spinner";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Toast from "./Toast";

// Define the SendMessage component
export default function SendMessage() {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [headerVariables, setHeaderVariables] = useState([]);
  const [bodyVariables, setBodyVariables] = useState([]);
  const [preview, setPreview] = useState({ header: "", body: "", footer: "" });
  const [customerName, setCustomerName] = useState("");
  const [customerError, setCustomerError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [headerComponentText, setHeaderComponentText] = useState("");
  const [bodyComponentText, setBodyComponentText] = useState("");
  const [headerInputValues, setHeaderInputValues] = useState({});
  const [bodyInputValues, setBodyInputValues] = useState({});
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem('token');
  const [toast, setToast] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/wa_templates?status=APPROVED`);
        setTemplates(response.data.templates);
      } catch (err) {
        setError("Failed to fetch templates.");
        showToast("Failed to fetch templates.", 'error');
      } finally {
        setLoading(false);
      }
    };
    fetchTemplates();
  }, []);

  const showToast = (message, type) => {
    setToast({ message, type });
    // setTimeout(() => setToast(null), 3000);
  };

  // if (loading) return <div className="text-center text-gray-500">Loading...</div>;
  // if (error) return <div className="text-red-500 text-center">{error}</div>;

  const handleCustomerNameChange = (e) => {
    const customerNameInput = e.target.value;
    // const regexForName = /^([^a-zA-Z]*[A-Za-z]){4}/;
    const regexForName = /^[a-zA-Z\s]{4,}$/;

    setCustomerName(customerNameInput);

    if (!regexForName.test(customerNameInput)) {
      setCustomerError("Name should contain alphabets only and at least 4 letters.");
    } else {
      setCustomerError("");
    }
  };

  // Handle phone number input change and validation
  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    const regex = /^91\d{10}$/;

    setPhoneNumber(input);

    if (!regex.test(input)) {
      setPhoneError("Phone number must start with '91' and be followed by exactly 10 digits.");
    } else {
      setPhoneError("");
    }
  };

  // Handle template selection
  const handleTemplateChange = (e) => {
    const template = templates.find((t) => t.template_id === e.target.value);
    setSelectedTemplate(template);

    setHeaderVariables([]);
    setBodyVariables([]);
    setHeaderInputValues({});
    setBodyInputValues({});

    if (template) {
      const { components } = template;
      // Extract header and body placeholders
      const headerComponent = components.find((c) => c.type === "HEADER");
      const bodyComponent = components.find((c) => c.type === "BODY");
      const footerComponent = components.find((c) => c.type === "FOOTER");

      // Update placeholders
      setPreview({
        header: headerComponent?.text || "",
        body: bodyComponent?.text || "",
        footer: footerComponent?.text || "",
      });

      setHeaderVariables(headerComponent?.text?.match(/{{\d+}}/g) || []);
      setBodyVariables(bodyComponent?.text.match(/{{\d+}}/g) || []);
      setHeaderComponentText(headerComponent?.text);
      setBodyComponentText(bodyComponent?.text);
    }
  };

  // Update preview with user input
  const updatePreview = (type, index, value) => {
    if (type === "header") {
      setHeaderInputValues((prev) => ({
        ...prev,
        [`header_variable${index + 1}`]: value,
      }));
    } else if (type === "body") {
      setBodyInputValues((prev) => ({
        ...prev,
        [`body_variable${index + 1}`]: value,
      }));
    }
    setPreview((prev) => {
      const sourceText = type === "header" ? headerComponentText : bodyComponentText;
      const inputValues = type === "header" ? headerInputValues : bodyInputValues;

      const updatedInputValues = {
        ...inputValues,
        [`${type}_variable${index + 1}`]: value,
      };

      const updatedText = Object.keys(updatedInputValues).reduce((text, key) => {
        const variableIndex = key.match(/\d+/)[0]; // Extract the variable index
        return text.replaceAll(`{{${variableIndex}}}`, updatedInputValues[key] || `{{${variableIndex}}}`);
      }, sourceText);

      // const newText = sourceText.replaceAll(`{{${index + 1}}}`, value);
      return { ...prev, [type]: updatedText };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!customerName || customerError || !phoneNumber || phoneError || !selectedTemplate) {
      showToast("Please fix validation errors and fill all required fields.", 'error');
      return;
    }

    const sendMessageData = {
      customer_name: customerName,
      phone_number: phoneNumber,
      template_id: selectedTemplate.template_id,
      ...headerInputValues,
      ...bodyInputValues
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/send_a_message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(sendMessageData),
      });

      if (response.ok) {
        const result = await response.json();
        showToast(result.message, 'success');
        setTimeout(() => {
          navigate("/history/view-sent-messages");
        }, 5000);
      } else {
        const errorResult = await response.json();
        showToast(`Error: ${errorResult.message}`, 'error');
      }
    } catch (error) {
      showToast("An error occurred while sending the message.", 'error');
    }
  };

  return (
    <div className="p-6 min-h-screen bg-gray-100 font-rubik">
      <CustomBreadcrumbs />
      {loading ? (
        <Spinner text="Fetching message templates..." />
      ) : (
        <div className="flex flex-col items-center space-y-4">
          <h3 className="text-2xl font-bold mb-2">Send a Message</h3>
          {/* Flash Message Alert */}
          {/* <div className="bg-red-100 text-red-600 border border-red-300 p-4 rounded-md w-full max-w-lg" role="alert">
              Error message will go here.
          </div> */}

          <div className="flex flex-col md:flex-row justify-center space-x-4 items-start w-full">
            {/* Form Section */}
            <form className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6 space-y-4" onSubmit={handleSubmit}>
              <div className="space-y-2">
                <label className="block text-gray-700 font-medium">Name *</label>
                <input type="text" placeholder="Customer's Name" value={customerName} onChange={handleCustomerNameChange} className="w-full border rounded-lg p-3 focus:outline-none focus:ring-2 ring-blue-400" required/>
                {customerError && <p className="text-red-500 text-xs">{customerError}</p>}
              </div>

              <div className="space-y-2">
                <label className="block text-gray-700 font-medium">Phone Number *</label>
                <input type="text" placeholder="Phone Number (Ex: 919840012345)" value={phoneNumber} onChange={handlePhoneNumberChange} className="w-full border rounded-lg p-3 focus:outline-none focus:ring-2 ring-blue-400" required/>
                {phoneError && <p className="text-red-500 text-xs">{phoneError}</p>}
              </div>

              <div className="space-y-2">
                <label className="block text-gray-700 font-medium">Message Template *</label>
                <select onChange={handleTemplateChange} className="w-full border rounded-lg p-3 focus:outline-none focus:ring-2 ring-blue-400" required>
                  <option value="">Select a template</option>
                  {templates.map((template) => (
                  <option key={template.template_id} value={template.template_id}>
                      {template.name}
                  </option>
                  ))}
                </select>
              </div>

              {/* Dynamic Input Fields for Header Variables */}
              {headerVariables.map((variable, index) => (
                <div key={index} className="space-y-2">
                  <label className="block text-gray-700 font-medium">Header Variable {variable}</label>
                  <input
                  type="text"
                  placeholder={`Enter value for ${variable}`}
                  className="w-full border rounded-lg p-3 focus:outline-none focus:ring-2 ring-blue-400"
                  value={headerInputValues[`header_variable${index + 1}`] || ""}
                  onChange={(e) => updatePreview("header", index, e.target.value)}
                  required
                  />
                </div>
              ))}

              {/* Dynamic Input Fields for Body Variables */}
              {bodyVariables.map((variable, index) => (
                <div key={index} className="space-y-2">
                  <label className="block text-gray-700 font-medium">Body Variable {variable}</label>
                  <input
                  type="text"
                  placeholder={`Enter value for ${variable}`}
                  className="w-full border rounded-lg p-3 focus:outline-none focus:ring-2 ring-blue-400"
                  value={bodyInputValues[`body_variable${index + 1}`] || ""}
                  onChange={(e) => updatePreview("body", index, e.target.value)}
                  required
                  />
                </div>
              ))}

              <button className="w-full bg-blue-600 text-white rounded-lg py-3 font-semibold hover:bg-blue-700 transition duration-300">
              Send
              </button>
            </form>

            {/* Preview Section */}
            <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6">
              <h3 className="text-center text-lg font-semibold text-gray-800 mb-4">Message Preview</h3>
              <div className="border rounded-lg p-4 bg-gray-50">
                {/* Header */}
                <div className="mb-4 text-left">
                  <p className="font-medium text-gray-700">{preview.header}</p>
                </div>
              
                {/* Body */}
                <div className="text-gray-600 whitespace-pre-wrap">
                  <p>{preview.body}</p>
                </div>

                {/* Footer */}
                <div className="mt-4 text-xs text-gray-400 text-left">
                  <p>{preview.footer}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {toast && <Toast message={toast.message} type={toast.type} onClose={() => setToast(null)} />}
    </div>
  );
}
