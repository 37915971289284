import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faBullhorn, faCogs, faRandom, faHistory, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { faFileLines, faPaperPlane, faEnvelope, faCalendarDays, faCalendarPlus, faBell } from '@fortawesome/free-regular-svg-icons';

const LandingPage = ({ userName }) => {
  return (
    // <div className="container mx-auto bg-gray-100 px-8 py-4 font-rubik">
    <div className='min-h-screen bg-gray-100 px-8 py-8 font-rubik'>
      <div className="text-center mb-8">
        <h3 className="text-2xl font-bold mb-2">Welcome, {userName}!</h3>
        <p className="text-gray-600">Quick access to key actions and features</p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
        {/* Add Message Template */}
        <div className="card text-center bg-white shadow-md rounded-lg p-4 hover:shadow-lg flex flex-col justify-between min-h-[100px]">
          <FontAwesomeIcon icon={faFileLines} className="text-2xl text-custom-blue mb-2" />
          <h5 className="card-title text-md font-semibold">Add Message Template</h5>
          <p className="card-text text-gray-600 mb-2 text-sm">Create new templates for your messages to use in campaigns.</p>
          <Link to="/message-templates/add-template" className="btn-main bg-custom-blue text-white rounded-full px-3 py-1 mt-auto hover:bg-custom-dark-blue inline-block mx-auto text-sm">
            Create Template
          </Link>
        </div>

        {/* Send a Message */}
        <div className="card text-center bg-white shadow-md rounded-lg p-4 hover:shadow-lg flex flex-col justify-between min-h-[100px]">
          <FontAwesomeIcon icon={faPaperPlane} className="text-2xl text-custom-blue mb-2" />
          <h5 className="card-title text-md font-semibold">Send Message</h5>
          <p className="card-text text-gray-600 mb-2 text-sm">Send a personalized message to a single customer easily.</p>
          <Link to="/message-templates/send-a-message" className="btn-main bg-custom-blue text-white rounded-full px-3 py-1 mt-auto hover:bg-custom-dark-blue inline-block mx-auto text-sm">
            Send Message
          </Link>
        </div>

        {/* Add Customer Segment */}
        <div className="card text-center bg-white shadow-md rounded-lg p-4 hover:shadow-lg flex flex-col justify-between min-h-[100px]">
          <FontAwesomeIcon icon={faUsers} className="text-2xl text-custom-blue mb-2" />
          <h5 className="card-title text-md font-semibold">Add Customer Segment</h5>
          <p className="card-text text-gray-600 mb-2 text-sm">Organize your customers into segments for targeted messaging.</p>
          <Link to="/customer-segments-v7/add-segment" className="btn-main bg-custom-blue text-white rounded-full px-3 py-1 mt-auto hover:bg-custom-dark-blue inline-block mx-auto text-sm">
            Add Segment
          </Link>
        </div>

        {/* Send Message to Segment */}
        <div className="card text-center bg-white shadow-md rounded-lg p-4 hover:shadow-lg flex flex-col justify-between min-h-[100px]">
          <FontAwesomeIcon icon={faBullhorn} className="text-2xl text-custom-blue mb-2" />
          <h5 className="card-title text-md font-semibold">Send Message to Segment</h5>
          <p className="card-text text-gray-600 mb-2 text-sm">Send a message to a specific customer segment.</p>
          <Link to="/send_a_message_to_segment" className="btn-main bg-custom-blue text-white rounded-full px-3 py-1 mt-auto hover:bg-custom-dark-blue inline-block mx-auto text-sm">
            Send to Segment
          </Link>
        </div>

        {/* View Sent Messages */}
        <div className="card text-center bg-white shadow-md rounded-lg p-4 hover:shadow-lg flex flex-col justify-between min-h-[100px]">
          <FontAwesomeIcon icon={faEnvelope} className="text-2xl text-custom-blue mb-2" />
          <h5 className="card-title text-md font-semibold">View Sent Messages</h5>
          <p className="card-text text-gray-600 mb-2 text-sm">Review the messages you've sent to customers.</p>
          <Link to="/history/view-sent-messages" className="btn-main bg-custom-blue text-white rounded-full px-3 py-1 mt-auto hover:bg-custom-dark-blue inline-block mx-auto text-sm">
            View Messages
          </Link>
        </div>

        {/* Manage Templates */}
        <div className="card text-center bg-white shadow-md rounded-lg p-4 hover:shadow-lg flex flex-col justify-between min-h-[100px]">
          <FontAwesomeIcon icon={faCogs} className="text-2xl text-custom-blue mb-2" />
          <h5 className="card-title text-md font-semibold">Manage Templates</h5>
          <p className="card-text text-gray-600 mb-2 text-sm">Edit, update, or delete your message templates.</p>
          <Link to="/message-templates" className="btn-main bg-custom-blue text-white rounded-full px-3 py-1 mt-auto hover:bg-custom-dark-blue inline-block mx-auto text-sm">
            Manage Templates
          </Link>
        </div>

        {/* Send Flow */}
        <div className="card text-center bg-white shadow-md rounded-lg p-4 hover:shadow-lg flex flex-col justify-between min-h-[100px]">
          <FontAwesomeIcon icon={faRandom} className="text-2xl text-custom-blue mb-2" />
          <h5 className="card-title text-md font-semibold">Send Flow</h5>
          <p className="card-text text-gray-600 mb-2 text-sm">Send a personalized flow to a single customer easily.</p>
          <Link to="/flows/send-a-flow" className="btn-main bg-custom-blue text-white rounded-full px-3 py-1 mt-auto hover:bg-custom-dark-blue inline-block mx-auto text-sm">
            Send Flow
          </Link>
        </div>

        {/* View Sent Flows */}
        <div className="card text-center bg-white shadow-md rounded-lg p-4 hover:shadow-lg flex flex-col justify-between min-h-[100px]">
          <FontAwesomeIcon icon={faHistory} className="text-2xl text-custom-blue mb-2" />
          <h5 className="card-title text-md font-semibold">View Sent Flows</h5>
          <p className="card-text text-gray-600 mb-2 text-sm">Review the flows you've sent to customers.</p>
          <Link to="/history/view-sent-flows" className="btn-main bg-custom-blue text-white rounded-full px-3 py-1 mt-auto hover:bg-custom-dark-blue inline-block mx-auto text-sm">
            View Sent Flows
          </Link>
        </div>

        {/* Scheduled Messages */}
        <div className="card text-center bg-white shadow-md rounded-lg p-4 hover:shadow-lg flex flex-col justify-between min-h-[100px]">
          <FontAwesomeIcon icon={faCalendarDays} className="text-2xl text-custom-blue mb-2" />
          <h5 className="card-title text-md font-semibold">Scheduled Messages</h5>
          <p className="card-text text-gray-600 mb-2 text-sm">View the Scheduled Messages.</p>
          <Link to="/scheduled-messages-cards" className="btn-main bg-custom-blue text-white rounded-full px-3 py-1 mt-auto hover:bg-custom-dark-blue inline-block mx-auto text-sm">
            Scheduled Messages
          </Link>
        </div>

        {/* Schedule Message */}
        <div className="card text-center bg-white shadow-md rounded-lg p-4 hover:shadow-lg flex flex-col justify-between min-h-[100px]">
          <FontAwesomeIcon icon={faCalendarPlus} className="text-2xl text-custom-blue mb-2" />
          <h5 className="card-title text-md font-semibold">Schedule Message</h5>
          <p className="card-text text-gray-600 mb-2 text-sm">Schedule a message to a specific customer segment.</p>
          <Link to="/schedule-message" className="btn-main bg-custom-blue text-white rounded-full px-3 py-1 mt-auto hover:bg-custom-dark-blue inline-block mx-auto text-sm">
            Schedule Message
          </Link>
        </div>

        {/* Notification Center */}
        <div className="card text-center bg-white shadow-md rounded-lg p-4 hover:shadow-lg flex flex-col justify-between min-h-[100px]">
          <FontAwesomeIcon icon={faBell} className="text-2xl text-custom-blue mb-2" />
          <h5 className="card-title text-md font-semibold">Notification Center</h5>
          <p className="card-text text-gray-600 mb-2 text-sm">View all the notifications.</p>
          <Link to="/notification-center" className="btn-main bg-custom-blue text-white rounded-full px-3 py-1 mt-auto hover:bg-custom-dark-blue inline-block mx-auto text-sm">
            Notification Center
          </Link>
        </div>

        {/* Reports */}
        <div className="card text-center bg-white shadow-md rounded-lg p-4 hover:shadow-lg flex flex-col justify-between min-h-[100px]">
          <FontAwesomeIcon icon={faClipboardList} className="text-2xl text-custom-blue mb-2" />
          <h5 className="card-title text-md font-semibold">Reports</h5>
          <p className="card-text text-gray-600 mb-2 text-sm">View all the reports.</p>
          <Link to="/reports" className="btn-main bg-custom-blue text-white rounded-full px-3 py-1 mt-auto hover:bg-custom-dark-blue inline-block mx-auto text-sm">
            Reports
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
