import React, { useState, useEffect } from 'react';
// import { FiSearch } from 'react-icons/fi'; // For a search icon
// import { AiOutlineUsergroupAdd } from 'react-icons/ai'; // For segment icon

const CustomerSegmentsPageV4 = () => {
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/customer_segments`);
        if (!response.ok) {
          throw new Error('Failed to fetch segments.');
        }
        const data = await response.json();
        setSegments(data.segments || []);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchSegments();
  }, [API_BASE_URL]);

  const handleSegmentClick = (segment) => {
    setSelectedSegment(segment);
  };

  const filteredSegments = segments.filter(segment =>
    segment.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-100 flex font-rubik">
      {/* Left Panel: List of Segments */}
      <div className="w-1/3 bg-white p-4 border-r border-gray-300 overflow-y-auto">
        <div className="flex items-center mb-4">
          {/* <FiSearch className="text-gray-400 mr-2" /> */}
          <input
            type="text"
            placeholder="Search segments..."
            className="w-full border rounded px-3 py-2"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <h2 className="text-xl font-semibold mb-4">Customer Segments</h2>
        {loading ? (
          <p className="text-gray-600">Loading...</p>
        ) : error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <ul className="space-y-2">
            {filteredSegments.map(segment => (
              <li
                key={segment.id}
                onClick={() => handleSegmentClick(segment)}
                className={`p-3 flex items-center cursor-pointer rounded-lg transition-all duration-200 ${
                  selectedSegment && selectedSegment.id === segment.id ? 'bg-blue-100 border-l-4 border-blue-500' : 'hover:bg-gray-100'
                }`}
              >
                {/* <AiOutlineUsergroupAdd className="text-blue-500 mr-3" /> */}
                <div>
                  <div className="text-gray-800 font-medium">{segment.name}</div>
                  <div className="text-sm text-gray-500">{segment.description || 'No description'}</div>
                  {segment.status && (
                    <span
                      className={`inline-block px-2 py-1 mt-1 text-xs font-semibold ${
                        segment.status === 'Active' ? 'text-green-700 bg-green-100' : 'text-red-700 bg-red-100'
                      } rounded-full`}
                    >
                      {segment.status}
                    </span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
        {!loading && segments.length === 0 && (
          <p className="text-center text-gray-600 mt-4">No customer segments found.</p>
        )}
      </div>

      {/* Right Panel: Segment Details */}
      <div className="w-2/3 bg-white p-6 transition-transform duration-300">
        {selectedSegment ? (
          <div>
            <h3 className="text-2xl font-bold mb-2">{selectedSegment.name}</h3>
            <p className="text-gray-700 mb-4">
              {selectedSegment.description || 'No description available'}
            </p>
            <div className="mt-4">
              <div className="text-gray-800 mb-2">
                <strong>Details</strong>
              </div>
              <div className="text-gray-600 mb-2">
                <p><strong>Created At:</strong> {new Date(selectedSegment.created_at).toLocaleDateString()}</p>
                <p><strong>Updated At:</strong> {new Date(selectedSegment.updated_at).toLocaleDateString()}</p>
                <p><strong>Number of Customers:</strong> {selectedSegment.customers.length}</p>
              </div>
            </div>
            <div className="mt-4 flex">
              <button className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-600">
                Edit
              </button>
              <button className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
                Delete
              </button>
            </div>
          </div>
        ) : (
          <p className="text-gray-500 text-center">Select a customer segment to see the details.</p>
        )}
      </div>
    </div>
  );
};

export default CustomerSegmentsPageV4;
