// NotificationFeed.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEnvelope, faExclamationTriangle, faInfoCircle, fnEnvelope } from '@fortawesome/free-solid-svg-icons';

const NotificationFeed = () => {
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem('token');
//   const notifications = [
//     { id: 1, message: "New message sent successfully.", type: "success", time: "2 mins ago" },
//     { id: 2, message: "Flow execution failed.", type: "error", time: "10 mins ago" },
//     { id: 3, message: "New user registered.", type: "info", time: "30 mins ago" },
//   ];

    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = () => {
        fetch(`${API_BASE_URL}/api/recent_notifications`, {headers: {Authorization: `Bearer ${token}`}})
            .then((response) => response.json())
            .then((data) => {
                setNotifications(data.notifications);
                setUnreadCount(data.unread_notifications_count);
            })
            .catch((error) => {
                console.error('Error fetching notifications:', error);
            });
    };

    const getNotificationIcon = (type) => {
        switch(type) {
            case 'success':
                return <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-2" />;
            case 'alert':
                return <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500 mr-2" />;
            case 'message':
                return <FontAwesomeIcon icon={faEnvelope} className="text-blue-500 mr-2" />;
            case 'info':
            default:
                return <FontAwesomeIcon icon={faInfoCircle} className="text-blue-500 mr-2" />;
        }
    };

    return (
        <div className="bg-white shadow-lg rounded-lg p-6 font-rubik">
            <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-semibold">Notifications</h3>
                {unreadCount > 0 && (
                    <span className="text-xs bg-red-500 text-white rounded-full px-2 py-0.5">
                        {unreadCount} new
                    </span>
                )}
            </div>
            <p className="text-xs text-gray-500 mb-4">Review the recent notifications.</p>

            <ul className="space-y-3 divide-y divide-gray-200">
                {notifications.length > 0 ? (
                    notifications.map((notification) => (
                        <li key={notification.id} className="pt-3">
                            <div className="flex items-center text-gray-700">
                                {getNotificationIcon(notification.type)}
                                <div className="text-sm font-medium flex-1">
                                    {notification.message}
                                </div>
                            </div>
                            <span className="text-xs text-gray-500">{notification.time}</span>
                        </li>
                    ))
                ) : (
                    <div className="p-2 text-gray-400 text-sm text-center">No new notifications.</div>
                )}
            </ul>

            {/* Link to Notification Center */}
            <div className="mt-4 text-center">
                {/* <Link to="/notification-center" className="text-blue-500 hover:text-blue-600 text-sm"> */}
                <Link to="/notification-center" className="px-2 py-1 rounded text-sm text-blue-600 hover:bg-blue-100 hover:text-blue-800 transition-colors">
                    View all notifications
                </Link>
            </div>
        </div>
    );
};

export default NotificationFeed;
