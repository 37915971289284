import React, { useState, useEffect } from "react";
import CustomBreadcrumbs from './CustomBreadcrumbs';
import axios from 'axios';
import Spinner from './Spinner';
import { useNavigate } from 'react-router-dom';
import Toast from "./Toast";

export default function SendFlow() {
  const [flows, setFlows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [expiresAt, setExpiresAt] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [customerError, setCustomerError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [previewLoading, setPreviewLoading] = useState(false);
  const [messageBody, setMessageBody] = useState('');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem('token');
  const [toast, setToast] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchFlows = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/flows?status=PUBLISHED`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setFlows(response.data.flows);
      } catch (err) {
        setError("Failed to fetch flows.");
        showToast("An error occurred while fetching flows.", 'error');
      } finally {
        setLoading(false);
      }
    };
    fetchFlows();
  }, []);

  const showToast = (message, type) => {
    setToast({ message, type });
    // setTimeout(() => setToast(null), 3000);
  };

  // if (loading) return <div className="text-center text-gray-500">Loading...</div>;
  // if (error) return <div className="text-red-500 text-center">{error}</div>;

  const fetchPreviewUrl = async (flowId) => {
    setPreviewLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/flow_preview/${flowId}`);
      if (response.data.preview_url) {
        setPreviewUrl(response.data.preview_url);
        setExpiresAt(response.data.expires_at);
      } else {
        setPreviewUrl(null);
        setExpiresAt(null);
        showToast("No preview available for the selected flow.", 'error');
      }
    } catch (error) {
      console.error("Error fetching preview:", error);
      setPreviewUrl(null);
      setExpiresAt(null);
      showToast("An error occurred while fetching the preview.", 'error');
    } finally {
      setPreviewLoading(false);
    }
  };

  const handleCustomerNameChange = (e) => {
    const customerNameInput = e.target.value.trim();
    // const regexForName = /^([^a-zA-Z]*[A-Za-z]){4}/;
    const regexForName = /^[a-zA-Z\s]{4,}$/;

    setCustomerName(customerNameInput);

    if (!regexForName.test(customerNameInput)) {
      setCustomerError("Name should contain alphabets only and at least 4 letters.");
    } else {
      setCustomerError("");
    }
  };

  // Handle phone number input change and validation
  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    const regex = /^91\d{10}$/;

    setPhoneNumber(input);

    if (!regex.test(input)) {
      setPhoneError("Phone number must start with '91' and be followed by exactly 10 digits.");
    } else {
      setPhoneError("");
    }
  };

  // Handle flow selection
  const handleFlowChange = (e) => {
    const flow = flows.find((t) => t.flow_id === e.target.value);
    setSelectedFlow(flow);

    if (flow) {
      fetchPreviewUrl(flow.flow_id); // Fetch preview URL as soon as a flow is chosen
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const sendFlowData = {
      customer_name: customerName,
      phone_number: phoneNumber,
      flow_id: selectedFlow.flow_id,
      message_body: messageBody
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/send_a_flow`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(sendFlowData),
      });

      if (response.ok) {
        const result = await response.json();
        showToast(result.message, 'success');
        setTimeout(() => {
          navigate("/history/view-sent-flows");
        }, 5000);
      } else {
        const errorResult = await response.json();
        showToast(`Error: ${errorResult.message}`, 'error');
      }
    } catch (error) {
      console.error("Error sending flow:", error);
      showToast("An error occurred while sending the flow.", 'error');
    }
  };

  return (
    <div className="p-6 min-h-screen bg-gray-50 font-rubik">
      <CustomBreadcrumbs />
      {loading ? (
        <Spinner text="Fetching flows..." /> // Show spinner when loading is true
      ) : (
        <div className="flex flex-col items-center space-y-4">
          <h3 className="text-2xl font-bold mb-2">Send a Flow</h3>
            
          {/* Flash Message Alert */}
          {/* <div className="bg-red-100 text-red-600 border border-red-300 p-4 rounded-md w-full max-w-lg" role="alert">
              Error message will go here.
          </div> */}

          {/* Form Section */}
          <form className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6 space-y-4" onSubmit={handleSubmit}>
            <div className="space-y-2">
                <label className="block text-gray-700 font-medium">Name *</label>
                <input type="text" placeholder="Customer's Name" value={customerName} onChange={handleCustomerNameChange} className="w-full border rounded-lg p-3 focus:outline-none focus:ring-2 ring-blue-400" required/>
                {customerError && <p className="text-red-500 text-xs">{customerError}</p>}
            </div>

            <div className="space-y-2">
              <label className="block text-gray-700 font-medium">Phone Number *</label>
              <input type="text" placeholder="Phone Number (Ex: 919840012345)" value={phoneNumber} onChange={handlePhoneNumberChange} className="w-full border rounded-lg p-3 focus:outline-none focus:ring-2 ring-blue-400" required/>
              {phoneError && <p className="text-red-500 text-xs">{phoneError}</p>}
            </div>

            <div className="space-y-2">
              <label className="block text-gray-700 font-medium">Flow *</label>
              <select onChange={handleFlowChange} className="w-full border rounded-lg p-3 focus:outline-none focus:ring-2 ring-blue-400" required>
                  <option value="">Select a flow</option>
                  {flows.map((flow) => (
                  <option key={flow.flow_id} value={flow.flow_id}>
                      {flow.flow_name}
                  </option>
                  ))}
              </select>
            </div>

            {/* <label className="block mb-2 text-sm font-medium text-gray-600">Message Body</label>
            <textarea
              className="w-full mb-4 p-2 border border-gray-300 rounded-md"
              value={messageBody}
              onChange={(e) => setMessageBody(e.target.value)}
            /> */}

            <button className="w-full bg-blue-600 text-white rounded-lg py-3 font-semibold hover:bg-blue-700 transition duration-300">
              Send
            </button>
          </form>

          {/* Preview Section */}
          <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6 mt-4">
            <h3 className="text-center text-lg font-semibold text-gray-800 mb-4">Flow Preview</h3>
            {previewLoading ? (
              <p className="text-gray-500">Loading preview...</p>
            ) : previewUrl ? (
              <div className="flex justify-between items-center">
                <a
                  href={previewUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-2 py-1 rounded text-blue-600 hover:bg-blue-100 hover:text-blue-800 transition-colors"
                >
                  View Flow Preview
                </a>
                <p className="text-gray-500">Expires at: {expiresAt}</p>
              </div>
            ) : (
              <p className="text-gray-500">Select a flow to see the preview.</p>
            )}
          </div>
        </div>
      )}
      {toast && <Toast message={toast.message} type={toast.type} onClose={() => setToast(null)} />}
    </div>
  );
}
