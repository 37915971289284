import React, { act, useState } from "react";

export default function Tabs() {
  const [activeTab, setActiveTab] = useState("Draft");

  const tabs = [
    { label: "Draft" },
    { label: "Expiring Soon" },
    { label: "Issued" },
    { label: "Pending Validation" },
    { label: "Expired" },
    { label: "Cancelled" },
  ];

//   const renderContent = () => {
//     switch (activeTab) {
//       case "Draft":
//         return <p>Draft Certificates Content</p>;
//       case "Expiring Soon":
//         return <p>Expiring Soon Certificates Content</p>;
//       case "Issued":
//         return <p>Issued Certificates Content</p>;
//       case "Pending Validation":
//         return <p>Pending Validation Certificates Content</p>;
//       case "Expired":
//         return <p>Expired Certificates Content</p>;
//       case "Cancelled":
//         return <p>Cancelled Certificates Content</p>;
//       default:
//         return null;
//     }
//   };

  const renderContent = () => {
    if (activeTab === "Issued" || activeTab === "Draft") {
      return (
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse border border-gray-200 text-sm">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left">Type</th>
                <th className="px-4 py-2 text-left">Domains</th>
                <th className="px-4 py-2 text-left">Status</th>
                <th className="px-4 py-2 text-left">Expires</th>
                <th className="px-4 py-2"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-2">90-Day SSL</td>
                <td className="px-4 py-2">example.com</td>
                <td className="px-4 py-2 text-green-600">Issued</td>
                <td className="px-4 py-2">Feb 18, 2025</td>
                <td className="px-4 py-2">
                  <button className="px-3 py-1 bg-blue-500 text-white rounded">Install</button>
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2">90-Day SSL</td>
                <td className="px-4 py-2">example.com</td>
                <td className="px-4 py-2 text-green-600">Issued</td>
                <td className="px-4 py-2">Feb 18, 2025</td>
                <td className="px-4 py-2">
                  <button className="px-3 py-1 bg-blue-500 text-white rounded">Install</button>
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2">90-Day SSL</td>
                <td className="px-4 py-2">example.com</td>
                <td className="px-4 py-2 text-green-600">Issued</td>
                <td className="px-4 py-2">Feb 18, 2025</td>
                <td className="px-4 py-2">
                  <button className="px-3 py-1 bg-blue-500 text-white rounded">Install</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
    return <p className="text-sm">{activeTab} Content</p>;
  };  

  return (
    <div className="bg-gray-100 min-h-screen p-6 font-rubik">
      <div className="max-w-6xl mx-auto bg-white p-4 rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold mb-6">Certificates</h1>
        <div className="flex border-b border-gray-200">
          {tabs.map((tab) => (
            <button
              key={tab.label}
              onClick={() => setActiveTab(tab.label)}
              className={`px-6 py-2 text-sm font-medium 
                ${
                  activeTab === tab.label
                    ? "border-b-2 border-blue-500 text-blue-500"
                    : "text-gray-600 hover:text-blue-500"
                }`}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div className="mt-6">{renderContent()}</div>
      </div>
    </div>
  );
}
