import React from 'react';
import CustomBreadcrumbs from './CustomBreadcrumbs';

const Customers = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-6 font-rubik">
      <CustomBreadcrumbs />
      <h2 className="text-2xl font-semibold mb-4">Customers</h2>
      <p className="text-gray-600 mb-6">Customers.</p>
      {/* Placeholder content */}
      <p className="text-gray-500">Coming soon!</p>
    </div>
  );
};

export default Customers;
