import React, { useState, useEffect } from 'react';
import TemplateList from './MessageTemplatesList';
import axios from 'axios';

const MessageTemplates = () => {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        axios.get(`${API_BASE_URL}/api/message_templates`)
        .then(response => {
            setTemplates(response.data.templates);
            setLoading(false);
        })
        .catch(error => {
            setError('Failed to fetch templates.');
            setLoading(false);
        });
    }, []);

    if (loading) return <div className="text-center text-gray-500">Loading...</div>;
    if (error) return <div className="text-red-500 text-center">{error}</div>;

    return (
        <div className="p-8 min-h-screen bg-gray-100 font-rubik">
        <h3 className="text-2xl font-bold mb-4">Message Templates</h3>
        <TemplateList templates={templates} />
        </div>
    );
};

export default MessageTemplates;
