import React, { useState, useEffect } from 'react';

const CustomerSegmentsPageV3 = () => {
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/customer_segments`);
        if (!response.ok) {
          throw new Error('Failed to fetch segments.');
        }
        const data = await response.json();
        setSegments(data.segments || []);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchSegments();
  }, [API_BASE_URL]);

  const handleSegmentClick = (segment) => {
    setSelectedSegment(segment);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex font-rubik">
      {/* Left Panel: List of Segments */}
      <div className="w-1/3 bg-white p-4 border-r border-gray-300 overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">Customer Segments</h2>
        {loading ? (
          <p className="text-gray-600">Loading...</p>
        ) : error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <ul className="space-y-2">
            {segments.map(segment => (
              <li
                key={segment.id}
                onClick={() => handleSegmentClick(segment)}
                className={`p-2 cursor-pointer rounded-lg hover:bg-gray-100 ${
                  selectedSegment && selectedSegment.id === segment.id ? 'bg-blue-100' : ''
                }`}
              >
                <div className="text-gray-800 font-medium">{segment.name}</div>
                <div className="text-gray-500 text-sm">{segment.description || 'No description'}</div>
              </li>
            ))}
          </ul>
        )}
        {!loading && segments.length === 0 && (
          <p className="text-center text-gray-600 mt-4">No customer segments found.</p>
        )}
      </div>

      {/* Right Panel: Segment Details */}
      <div className="w-2/3 bg-white p-6">
        {selectedSegment ? (
          <div>
            <h3 className="text-2xl font-bold mb-2">{selectedSegment.name}</h3>
            <p className="text-gray-700 mb-4">
              {selectedSegment.description || 'No description available'}
            </p>
            <div className="text-gray-600">
              <p><strong>Created At:</strong> {new Date(selectedSegment.created_at).toLocaleDateString()}</p>
              <p><strong>Updated At:</strong> {new Date(selectedSegment.updated_at).toLocaleDateString()}</p>
              <p><strong>Number of Customers:</strong> {selectedSegment.customers.length}</p>
            </div>
            {/* Add more details or actions if needed */}
            <div className="mt-4">
              <button className="bg-blue-500 text-white px-4 py-1 rounded mr-2 hover:bg-blue-600">Edit</button>
              <button className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600">Delete</button>
            </div>
          </div>
        ) : (
          <p className="text-gray-500 text-center">Select a customer segment to see the details.</p>
        )}
      </div>
    </div>
  );
};

export default CustomerSegmentsPageV3;
