import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function UserActivityLogs() {
  const { userId } = useParams();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [activeTab, setActiveTab] = useState("login");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchLogs = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/admin/users/${userId}/logs`, {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: {
              action: activeTab,
              page,
              per_page: 5
            }
          }
        );
        setLogs(response.data.logs);
        setPagination(response.data.pagination);
      } catch (err) {
        setError("Failed to fetch logs.");
      } finally {
        setLoading(false);
      }
    };
    fetchLogs();
  }, [userId, activeTab, page]);

  const tabs = [
    { label: "Login", value: "login" },
    { label: "Add Template", value: "add_template" },
    { label: "Send a Message", value: "send_a_message" },
    { label: "Send a Flow", value: "send_a_flow" },
    { label: "Others", value: "others" },
  ];

  return (
    <div className="p-6 bg-gray-100 min-h-screen font-rubik">
      {/* <CustomBreadcrumbs /> */}
      {/* Back to Home */}
      <div className="mb-2 text-sm flex justify-between items-center">
        <button
          className="flex items-center px-2 py-1 rounded-full text-blue-600 hover:bg-blue-100 hover:text-blue-800"
          onClick={() => navigate("/users")}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back to Users
        </button>
      </div>
      <h1 className="text-3xl font-semibold mb-4">User Activity Logs</h1>
      <p className="text-gray-600 mb-6">Monitor the user's activity by action type.</p>

      {/* Tabs */}
      <div className="flex border-b border-gray-200 mb-6">
        {tabs.map((tab) => (
          <button
            key={tab.value}
            onClick={() => {
              setActiveTab(tab.value);
              setPage(1); // Reset to first page on tab switch
            }}
            className={`px-6 py-2 text-sm font-medium 
              ${
                activeTab === tab.value
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-600 hover:text-blue-500"
              }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {loading ? (
        // <p>Loading...</p>
        <Spinner text="Fetching logs..." />
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : logs.length === 0 ? (
        <p className="text-gray-500">No logs available for this action.</p>
      ) : (
        <>
          <table className="min-w-full bg-white shadow-md rounded-lg border text-sm">
            <thead>
              <tr>
                <th className="text-left px-4 py-2">Timestamp</th>
                <th className="text-left px-4 py-2">Action</th>
                <th className="text-left px-4 py-2">Details</th>
                <th className="text-left px-4 py-2">IP Address</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log, index) => (
                <tr key={index} className="hover:bg-gray-200 border-t">
                  <td className="px-4 py-2">{new Date(log.created_at).toLocaleString()}</td>
                  <td className="px-4 py-2">{log.action}</td>
                  <td className="px-4 py-2">{log.details}</td>
                  <td className="px-4 py-2">{log.ip_address}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* <div className="mt-4 flex justify-between"> */}
          <div className="mt-4 flex justify-end gap-4">
            <button
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
              // className="bg-blue-600 text-white p-2 rounded disabled:opacity-50"
              className={`px-4 py-2 rounded ${page === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
            >
              Previous
            </button>
            <button
              disabled={page === pagination.total_pages || logs.length === 0}
              onClick={() => setPage(page + 1)}
              // className="bg-blue-600 text-white p-2 rounded disabled:opacity-50"
              className={`px-4 py-2 rounded ${page === pagination.total_pages || logs.length === 0 ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}
