import React, { useEffect, useState } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import Spinner from "./Spinner";

export default function InsightsDashboard() {
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState(null);
  const [trends, setTrends] = useState(null);
  const [error, setError] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState("7");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchInsights = async (period) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/admin/insights?period=${period}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSummary(response.data.summary);
      setTrends(response.data.trends);
    } catch (err) {
      setError("Failed to load insights.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInsights(selectedPeriod);
  }, [selectedPeriod]);

  return (
    <div className="p-6 bg-gray-100 min-h-screen max-w-6xl mx-auto font-rubik">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Insights Dashboard</h2>
      <p className="text-gray-600 mb-6">View insights from the data.</p>
      {error ? (
        <div className="text-red-500 bg-red-100 p-4 rounded flex items-center justify-between">
          <p>{error}</p>
          <button 
            className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded"
            onClick={() => window.location.reload()}
          >
            Retry
          </button>
        </div>
      ) : loading ? (
        <Spinner text="Fetching insights data..." />
      ) : (
        <>
          {/* Metrics Section */}
          <div className="grid grid-cols-3 gap-4 mb-6">
            <div className="p-4 bg-white rounded-lg shadow-md">
              <h2 className="text-xl font-semibold">Total Messages Sent</h2>
              <p className="text-3xl font-bold">{summary?.totalMessages || "..."}</p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow-md">
              <h2 className="text-xl font-semibold">Active Users</h2>
              <p className="text-3xl font-bold">{summary?.activeUsers || "..."}</p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow-md">
              <h2 className="text-xl font-semibold">Delivery Success Rate</h2>
              <p className="text-3xl font-bold">{summary?.deliveryRate || "..."}%</p>
            </div>
          </div>

          {/* Trends Section */}
          <div className="bg-white p-6 rounded-lg shadow-md mb-6 slide-up">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold mb-4">Message Trends</h2>
              <div className="space-x-2">
                <label className="text-gray-700 font-medium">Last:</label>
                <button
                  className={`p-2 text-sm rounded-full ${selectedPeriod === '7' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
                  onClick={() => {
                    setSelectedPeriod('7');
                  }}
                >
                  7 Days
                </button>
                <button
                  className={`p-2 text-sm rounded-full ${selectedPeriod === '14' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
                  onClick={() => {
                    setSelectedPeriod('14');
                  }}
                >
                  14 Days
                </button>
                <button
                  className={`p-2 text-sm rounded-full ${selectedPeriod === '30' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
                  onClick={() => {
                    setSelectedPeriod('30');
                  }}
                >
                  30 Days
                </button>
                <button
                  className={`p-2 text-sm rounded-full ${selectedPeriod === '90' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
                  onClick={() => {
                    setSelectedPeriod('90');
                  }}
                >
                  90 Days
                </button>
              </div>
              {/* <select
                  id="periodSelect"
                  value={selectedPeriod}
                  onChange={(e) => setSelectedPeriod(e.target.value)}
                  className="p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500"
              >
                  <option value="7">Last 7 Days</option>
                  <option value="14">Last 14 Days</option>
                  <option value="30">Last 30 Days</option>
              </select> */}
            </div>
            {trends ? (
              <div style={{ height: "400px", marginBottom: "20px" }}>
                <Line
                  data={{
                    labels: trends.dates,
                    datasets: [
                      {
                        label: "Messages Sent",
                        data: trends.messageCounts,
                        borderColor: "rgba(75, 192, 192, 1)",
                        backgroundColor: "rgba(75, 192, 192, 0.2)",
                      },
                      {
                        label: "Flows Sent",
                        data: trends.flowCounts,
                        borderColor: "rgba(54, 162, 235, 1)",
                        backgroundColor: "rgba(54, 162, 235, 0.2)",
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      tooltip: {
                        mode: "index",
                        intersect: false,
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          maxRotation: 45,
                          minRotation: 0,
                        },
                      },
                      y: {
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              </div>
            ) : (
              <p>Loading chart...</p>
            )}
          </div>

          {/* Template Insights */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Most Used Templates</h2>
            <table className="min-w-full bg-white border rounded-lg">
              <thead>
                <tr>
                  <th className="text-left px-4 py-2">Template Name</th>
                  <th className="text-left px-4 py-2">Usage Count</th>
                  <th className="text-left px-4 py-2">Failed Count</th>
                  <th className="text-left px-4 py-2">Success Rate</th>
                </tr>
              </thead>
              <tbody>
                {summary?.topTemplates.map((template, index) => (
                  <tr key={index} className="hover:bg-gray-200 border-t">
                    <td className="px-4 py-2">{template.name}</td>
                    <td className="px-4 py-2">{template.usageCount}</td>
                    <td className="px-4 py-2">{template.failedCount}</td>
                    <td className="px-4 py-2">{template.successRate}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Flow Insights */}
          <div className="bg-white p-6 rounded-lg shadow-md mt-6">
            <h2 className="text-xl font-semibold mb-4">Most Used Flows</h2>
            <table className="min-w-full bg-white border rounded-lg">
              <thead>
                <tr>
                  <th className="text-left px-4 py-2">Flow Name</th>
                  <th className="text-left px-4 py-2">Usage Count</th>
                  <th className="text-left px-4 py-2">Failed Count</th>
                  <th className="text-left px-4 py-2">Success Rate</th>
                </tr>
              </thead>
              <tbody>
                {summary?.topFlows.map((flow, index) => (
                  <tr key={index} className="hover:bg-gray-200 border-t">
                    <td className="px-4 py-2">{flow.name}</td>
                    <td className="px-4 py-2">{flow.usageCount}</td>
                    <td className="px-4 py-2">{flow.failedCount}</td>
                    <td className="px-4 py-2">{flow.successRate}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}
