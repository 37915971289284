// SentMessagesFeed.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const SentMessagesFeed = () => {
    const [messages, setMessages] = useState([]);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchMessages();
    }, []);

    const fetchMessages = () => {
        fetch(`${API_BASE_URL}/api/recent_messages_sent`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setMessages(data.messages);
            })
            .catch((error) => {
                console.error('Error fetching messages:', error);
            });
    };

    const getStatusColor = (statusType) => {
        switch (statusType) {
            case "accepted":
                return "bg-blue-100 text-blue-600"; // light blue for "accepted"
            case "sent":
                return "bg-yellow-100 text-yellow-600"; // light yellow for "sent"
            case "delivered":
                return "bg-green-100 text-green-600"; // light green for "delivered"
            case "read":
                return "bg-indigo-100 text-indigo-600"; // light indigo for "read"
            case "failed":
                return "bg-red-100 text-red-600"; // light red for "failed"
            default:
                return "bg-gray-100 text-gray-600"; // gray for unknown statuses
        }
    };

    return (
        <div className="bg-white shadow-lg rounded-lg p-6 font-rubik">
            <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-semibold">Recent Messages Sent</h3>
            </div>
            <p className="text-xs text-gray-500 mb-4">Review the recent messages.</p>

            <ul className="space-y-3 divide-y divide-gray-200">
                {messages.map((message) => (
                    <li key={message.id} className="pt-3">
                        <div className="flex justify-between items-center text-gray-700">
                            <div className="text-sm font-medium flex-1">
                                {message.customer_name} ({message.customer_phone_number})
                                <div className="text-xs text-gray-500 mt-1">
                                    Template: {message.message_template_name || "N/A"}
                                </div>
                            </div>
                            <div>
                                <span className={`text-sm font-semibold py-1 px-3 rounded-lg ${getStatusColor(message.latest_status)}`}>
                                {/* {message.latest_status} */}
                                {message.latest_status.charAt(0).toUpperCase() + message.latest_status.slice(1)}
                                </span>
                            </div>
                        </div>
                        <span className="text-xs text-gray-500">{message.message_sent_time}</span>
                    </li>
                ))}
            </ul>

            {/* Link to View Sent Messages */}
            <div className="mt-4 text-center">
                <Link to="/history/view-sent-messages" className="px-2 py-1 rounded text-sm text-blue-600 hover:bg-blue-100 hover:text-blue-800 transition-colors">
                    View all messages sent
                </Link>
            </div>
        </div>
    );
};

export default SentMessagesFeed;
