import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { faImage, faFileLines } from '@fortawesome/free-regular-svg-icons';

// const templates = [
//   { id: 1, name: "diwali_text", icon: "🎂" },
//   { id: 2, name: "hello_world", icon: "🎄" },
//   { id: 3, name: "bsf_offer", icon: "🎉" },
//   { id: 4, name: "happy_birthday_image", icon: "💸" },
//   { id: 5, name: "happy_birthday_text", icon: "🙏" },
//   { id: 6, name: "bsf_offer1", icon: "🎊" },
// ];

const TemplateSelectionModal = ({ isOpen, templates, onClose, onConfirm }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  // Filter templates based on search query
  const filteredTemplates = templates.filter((template) =>
    template.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle template selection toggle
  const toggleTemplateSelection = (template) => {
    setSelectedTemplates((prev) =>
      prev.includes(template)
        ? prev.filter((t) => t !== template) // Deselect
        : [...prev, template] // Select
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-3xl p-6">
        {/* Modal Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Select Templates</h2>
          <button
            className="text-gray-600 hover:text-gray-800"
            onClick={onClose}
          >
            ✕
          </button>
        </div>

        {/* Search Input */}
        <input
          type="text"
          placeholder="Search templates..."
          className="w-full border border-gray-300 rounded p-2 mb-4"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        {/* Grid View */}
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
          {filteredTemplates.map((template) => (
            <div
              key={template.id}
              className={`border rounded-lg p-4 flex flex-col items-center text-center cursor-pointer ${
                selectedTemplates.includes(template)
                  ? "border-blue-500 bg-blue-100"
                  : "border-gray-300"
              }`}
              onClick={() => toggleTemplateSelection(template)}
            >
              {/* <div className="text-3xl">{template.icon}</div> */}
              {template.header_format === 'IMAGE' && (
                <FontAwesomeIcon icon={faImage} className="text-blue-600" />
              )}
              {template.header_format === 'VIDEO' && (
                <FontAwesomeIcon icon={faVideo} className="text-blue-600" />
              )}
              {template.header_format === 'TEXT' && (
                <FontAwesomeIcon icon={faFileLines} className="text-blue-600" />
              )}
              <p className="mt-2 text-sm">{template.name}</p>
              <p className="mt-2 text-gray-400 text-[10px]">{template.category}</p>
            </div>
          ))}
        </div>

        {/* Selected Templates Summary */}
        <div className="mt-4">
          <p className="text-sm text-gray-700">
            Selected Templates:{" "}
            <span className="font-medium">
              {selectedTemplates.length > 0
                ? selectedTemplates.map((t) => t.name).join(", ")
                : "None"}
            </span>
          </p>
        </div>

        {/* Actions */}
        <div className="mt-6 flex justify-end">
          <button
            className="px-4 py-2 text-sm rounded-md shadow-sm bg-gray-200 rounded hover:bg-gray-300 mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 text-sm rounded-md shadow-sm bg-blue-600 text-white rounded hover:bg-blue-700"
            onClick={() => {
              onConfirm(selectedTemplates); // Pass selected templates to parent
              onClose();
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default TemplateSelectionModal;
