import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const SettingsPage = ({ userDetails }) => {
    const [activeTab, setActiveTab] = useState('Profile');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [bio, setBio] = useState('');
    const [urls, setUrls] = useState(['']);
    const [fontFamily, setFontFamily] = useState('');
    const [userDetailsState, setUserDetailsState] = useState(userDetails);
    const [editedDetails, setEditedDetails] = useState({ ...userDetailsState });
    const [notificationSettings, setNotificationSettings] = useState({});
    // const [editedNotificationSettings, setEditedNotificationSettings] = useState({});
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    // Scroll to the top when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        // Fetch notification settings from the backend API
        fetchNotificationSettings();
    }, []);

    const fetchNotificationSettings = () => {

        fetch(`${API_BASE_URL}/api/user_settings`)
            .then((response) => response.json())
            .then((data) => {
                setNotificationSettings(data.user_settings);
                // setEditedNotificationSettings(...data.user_settings);
            })
            .catch((error) => {
                console.error('Error fetching notification settings:', error);
            });
    };

    const handleAddUrl = () => setUrls([...urls, '']);
    const handleUrlChange = (index, value) => {
        const newUrls = [...urls];
        newUrls[index] = value;
        setUrls(newUrls);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({ username, email, bio, urls });
    };

    const [notificationType, setNotificationType] = useState('Nothing');
    const [emailNotifications, setEmailNotifications] = useState({
        communication: false,
        marketing: false,
        social: false,
        security: false,
        some: false
    });
    const [mobileSettings, setMobileSettings] = useState(false);

    const handleToggle = (type) => {
        setNotificationSettings((prev) => ({
        ...prev,
        [type]: !prev[type],
        }));
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Profile':
                return (
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="mb-4 pb-4 border-b border-gray-300 max-w-3xl space-y-2">
                    <h3 className="text-xl font-bold">Profile</h3>
                    <p className="text-sm text-gray-500">This is how others will see you on the site.</p>
                    </div>
                    {/* Username */}
                    <div>
                    <label className="block text-sm font-medium text-gray-700">Username</label>
                    <input
                        type="text"
                        value={userDetails.user_name}
                        onChange={(e) => setUsername(e.target.value)}
                        className="w-full max-w-md border rounded px-3 py-2 mt-1"
                        placeholder="Enter your username"
                        disabled
                    />
                    <p className="text-xs text-gray-500 mt-1">
                        This is your public display name. It can be your real name or a pseudonym. You can only change this once every 30 days.
                    </p>
                    </div>
                    {/* Email */}
                    <div>
                    <label className="block text-sm font-medium text-gray-700">Email</label>
                    {/* <select
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full max-w-md border rounded px-3 py-2 mt-1"
                    >
                        <option value="">Select a verified email to display</option>
                    </select> */}
                    <input
                        type="text"
                        value={userDetails.user_email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full max-w-md border rounded px-3 py-2 mt-1"
                        placeholder="Enter your email"
                        disabled
                    />
                    <p className="text-xs text-gray-500 mt-1">You can manage verified email addresses in your email settings.</p>
                    </div>
                    {/* Bio */}
                    <div>
                    <label className="block text-sm font-medium text-gray-700">Bio</label>
                    <textarea
                        value={editedDetails.bio}
                        onChange={(e) => setBio(e.target.value)}
                        className="w-full max-w-md border rounded px-3 py-2 mt-1"
                        placeholder="Tell us a little bit about yourself"
                        rows="2"
                    />
                    </div>
                    {/* URLs */}
                    {/* <div>
                    <label className="block text-sm font-medium text-gray-700">URLs</label>
                    {urls.map((url, index) => (
                        <input
                        key={index}
                        type="url"
                        value={url}
                        onChange={(e) => handleUrlChange(index, e.target.value)}
                        className="w-full max-w-md border rounded px-3 py-2 mt-1 mb-2"
                        placeholder="https://example.com"
                        />
                    ))}
                    <button type="button" onClick={handleAddUrl} className="text-blue-600 text-sm font-medium">
                        + Add URL
                    </button>
                    </div> */}
                    <button type="submit" className="bg-black text-white font-medium px-4 py-2 rounded mt-4 hover:bg-gray-800">
                    Update Profile
                    </button>
                </form>
            );

        case 'Account':
            return (
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="mb-4 pb-4 border-b border-gray-300 max-w-3xl space-y-2">
                        <h3 className="text-xl font-bold">Account</h3>
                        <p className="text-sm text-gray-500">Update your account settings. Set your preferred language and timezone.</p>
                    </div>
                    <div className="space-y-4 max-w-md mt-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Change Password</label>
                        <input type="password" placeholder="Current Password" className="w-full border rounded px-3 py-2 mt-1" />
                        <input type="password" placeholder="New Password" className="w-full border rounded px-3 py-2 mt-2" />
                        <input type="password" placeholder="Confirm New Password" className="w-full border rounded px-3 py-2 mt-2" />
                    </div>
                    {/* <div>
                        <label className="block text-sm font-medium text-gray-700">Two-Factor Authentication</label>
                        <input type="checkbox" className="mt-1" /> Enable two-factor authentication
                    </div> */}
                    </div>
                    <div key="some" className="flex items-center justify-between border rounded-lg p-4">
                        <div>
                            <h5 className="font-medium">Two-Factor Authentication</h5>
                            <p className="text-gray-500 text-sm">Enable two-factor authentication</p>
                        </div>
                        <button
                            type="button"
                            onClick={() => handleToggle('some')}
                            className={`w-12 h-6 flex items-center rounded-full p-1 transition-colors ${
                            emailNotifications['some'] ? 'bg-blue-600' : 'bg-gray-300'
                            }`}
                        >
                            <div
                            className={`h-4 w-4 bg-white rounded-full transition-transform ${
                                emailNotifications['some'] ? 'translate-x-6' : ''
                            }`}
                            ></div>
                        </button>
                    </div>
                </form>
            );

        case 'Appearance':
            return (
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="mb-4 pb-4 border-b border-gray-300 max-w-3xl space-y-2">
                        <h3 className="text-xl font-bold">Appearance</h3>
                        <p className="text-sm text-gray-500">Customize the appearance of the app. Automatically switch between day and night themes.</p>
                    </div>
                    <div className="space-y-4 max-w-md mt-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Font Family</label>
                            <select
                                value={fontFamily}
                                onChange={(e) => setFontFamily(e.target.value)}
                                className="w-full max-w-md border rounded px-3 py-2 mt-1"
                            >
                                <option value="">Select a font family</option>
                                <option value="Rubik">Rubik</option>
                            </select>
                            <p className="text-xs text-gray-500 mt-1">Set the font you want to use.</p>
                        </div>
                        <div className='mt-4'>
                            <label className="block text-sm font-medium text-gray-700">Theme</label>
                            <select className="w-full max-w-md border rounded px-3 py-2 mt-1">
                                <option>Light</option>
                                <option>Dark</option>
                                <option>System Default</option>
                            </select>
                            <p className="text-xs text-gray-500 mt-1">Set the theme you want to use.</p>
                        </div>
                    </div>
                </form>
            );

        case 'Notifications':
            return (
            <form className="space-y-6">
                {/* Section Header */}
                <div className="mb-4 pb-4 border-b border-gray-300 max-w-3xl space-y-2">
                <h3 className="text-xl font-bold">Notifications</h3>
                <p className="text-sm text-gray-500">Configure how you receive notifications.</p>
                </div>

                {/* Notification Types */}
                {/* <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Notify me about</label>
                <div className="flex flex-col space-y-1">
                    {['All new messages', 'Direct messages and mentions', 'Nothing'].map((option) => (
                    <label key={option} className="flex items-center">
                        <input
                        type="radio"
                        value={option}
                        checked={notificationType === option}
                        onChange={() => setNotificationType(option)}
                        className="mr-2"
                        />
                        {option}
                    </label>
                    ))}
                </div>
                </div> */}

                {/* App Notifications */}
                <div className="space-y-2">
                <h4 className="text-lg font-semibold">In-App Notifications</h4>
                <p className="text-sm text-gray-500">Choose which types of notifications you would like to receive.</p>
                
                {[
                    { label: 'Message Template Updates', description: 'Receive notifications about message templates.', type: 'message_template_updates' },
                    { label: 'Message Delivery Failures', description: 'Receive notifications about the message delivery failures.', type: 'message_delivery_failures' },
                    { label: 'Scheduled Message Updates', description: 'Receive notifications about the scheduled messages.', type: 'stop_promotion_responses' },
                    { label: 'Responses with \'Stop promotions\' or \'STOP\'', description: 'Receive notifications if any customer responded with \'Stop promotions\' or \'STOP\'.', type: 'scheduled_message_updates' },
                ].map(({ label, description, type }) => (
                    <div key={type} className="flex items-center justify-between border rounded-lg p-4">
                        <div>
                            <h5 className="font-medium">{label}</h5>
                            <p className="text-gray-500 text-sm">{description}</p>
                        </div>
                        <button
                            type="button"
                            onClick={() => handleToggle(type)}
                            className={`w-12 h-6 flex items-center rounded-full p-1 transition-colors ${
                                notificationSettings[type] ? 'bg-blue-600' : 'bg-gray-300'
                            }`}
                        >
                            <div
                            className={`h-4 w-4 bg-white rounded-full transition-transform ${
                                notificationSettings[type] ? 'translate-x-6' : ''
                            }`}
                            ></div>
                        </button>
                    </div>
                ))}
                </div>

                {/* Mobile Settings Checkbox */}
                {/* <div className="flex items-center">
                <input
                    type="checkbox"
                    checked={mobileSettings}
                    onChange={() => setMobileSettings(!mobileSettings)}
                    className="mr-2"
                />
                <label className="text-sm font-medium text-gray-700">
                    Use different settings for my mobile devices
                </label>
                </div>
                <p className="text-xs text-gray-500 mt-1">
                You can manage your mobile notifications in the mobile settings page.
                </p> */}

                {/* Update Notifications Button */}
                <button
                type="submit"
                className="bg-black text-white font-medium px-4 py-2 rounded hover:bg-gray-800"
                >
                Update notifications
                </button>
            </form>
        );

            case 'Display':
                return (
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="mb-4 pb-4 border-b border-gray-300 max-w-3xl space-y-2">
                            <h3 className="text-xl font-bold">Display</h3>
                            <p className="text-sm text-gray-500">Turn items on or off to control what's displayed in the app.</p>
                        </div>
                        <div className="space-y-4 max-w-md mt-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Text Alignment</label>
                                <select className="w-full border rounded px-3 py-2 mt-1">
                                <option>Left</option>
                                <option>Center</option>
                                <option>Right</option>
                                </select>
                            </div>
                        </div>
                </form>
                );

      // Other tab cases like 'Profile', 'Account', etc.

      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col font-rubik">
      {/* Header */}
      <div className="bg-white px-10 pt-6 pb-4 border-b border-gray-300 flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold">Settings</h2>
          <p className="text-gray-600">Manage your account settings and set e-mail preferences.</p>
        </div>
        {/* <a
          href="/landing-page"
          title="Home"
          className="text-black-600 hover:text-blue-600 flex items-center px-4 py-2 rounded hover:bg-blue-100"
        >
          <FontAwesomeIcon icon={faHome} className="h-6 w-6 mr-2" />
          <span className="font-medium">Home</span>
          <span className="font-semibold text-lg">Home</span>
        </a> */}
      </div>

      {/* Main Content Area */}
      <div className="flex flex-1">
        {/* Sidebar */}
        <div className="w-1/5 bg-white p-10">
          <ul className="space-y-2">
            {['Profile', 'Account', 'Appearance', 'Notifications', 'Display'].map((item) => (
              <li
                key={item}
                onClick={() => setActiveTab(item)}
                className={`cursor-pointer p-2 rounded ${
                  activeTab === item ? 'bg-blue-100 text-blue-600 font-medium' : 'text-gray-600'
                }`}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>

        {/* Main Content */}
        <div className="w-4/5 bg-white p-6">
          <div className="space-y-6 max-w-3xl">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
