// OverviewCard.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTrashAlt, faArrowLeft, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const OverviewCard = ({ title, value, icon }) => (
  <div className="bg-white rounded-lg shadow-md p-6 flex items-center justify-between">
    <div className="flex items-center space-x-4">
      <div className={`p-3 rounded-full bg-blue-100 text-blue-500`}>
        {/* Display icon if provided */}
        {/* {icon} */}
        <FontAwesomeIcon icon={faEnvelope} />
      </div>
      <div>
        <h3 className="text-lg font-semibold text-gray-700">{title}</h3>
        <p className="text-2xl font-bold text-gray-800">{value}</p>
      </div>
    </div>
  </div>
);

export default OverviewCard;
